/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react'

import { FiUserPlus } from 'react-icons/fi'
import { AiOutlineClose } from 'react-icons/ai'
import { BsChevronLeft, BiChevronRight, BiChevronLeft } from 'react-icons/bi'
import { Tooltip } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import Slider from 'react-slick'
import moment from 'moment'

import PhotoFrame from '../../components/PhotoFrame/PhotoFrame'
import { Creators as companiesActions } from '../../redux/ducks/companies'
import { Creators as userActions } from '../../redux/ducks/users'
import { clipboardCopy } from '../../helpers/clipboardUtils'
import AddCustomerModal from './addCustomerModal'
import UpdateStageModal from './updateStageModal'
import { confirmDialog } from 'primereact/confirmdialog' // To use confirmDialog method
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useHistory } from 'react-router-dom'
import { Toast } from 'primereact/toast'

const jobItem = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { jobItem, authToken, currency, user, jobIndex } = props
  const {
    assistants = [],
    candidates = [],
    company = {},
    companyId,
    owner = {},
    region = {},
    salary,
    title,
    _id
  } = jobItem

  const [jobModal, setJobModal] = useState({ visible: false, candidate: {} })
  const [jobUpdateModal, setJobUpdateModal] = useState({})

  const op = useRef({})
  const toastRef = useRef(null)

  const jobLeaveHandler = () => {
    dispatch(
      userActions.deleteCandidateInsideJob(
        _id,
        { userId: user._id },
        authToken,
        user
      )
    )
      .then((res) => {})
      .catch((err) => {})
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return (
      <BiChevronRight
        className={className}
        style={{
          ...style,
          display: 'block',
          width: '65px',
          color: '#909094',
          height: '65px'
        }}
        onClick={onClick}
      />
    )
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return (
      <BiChevronLeft
        className={className}
        style={{
          ...style,
          display: 'block',
          width: '65px',
          color: '#909094',
          height: '65px'
        }}
        onClick={onClick}
      />
    )
  }

  const Product4 = {
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 2,
    adaptiveHeight: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          adaptiveHeight: true
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true
        }
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true
        }
      }
    ]
  }

  const candidateStage = (stage) => {
    switch (stage) {
      case '-1':
        return '+'
      case '0':
        return 'S'
      case '-2':
        return 'R'
      case '1':
        return 'I.'
      case '2':
        return 'II.'
      case '3':
        return 'III.'
      case '4':
        return 'D'

      default:
        break
    }
  }

  return (
    <div className="job-item">
      <div className="job-title-container">
        <p className="job-title">{title}</p>
      </div>
      <div className="actions-container">
        <FiUserPlus
          className="add-btn"
          onClick={() => {
            if (!jobModal.visible) setJobModal({ visible: true, candidate: {} })
          }}
        />
        {user._id !== owner._id && (
          <AiOutlineClose
            className="rmv-btn"
            onClick={() => {
              confirmDialog({
                className: 'basic-salary-cdialog',
                message:
                  'Sind Sie sicher, dass Sie diesen Job verlassen möchten?',
                header: '',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Sicher',
                rejectLabel: 'Abbrechen',
                accept: () => jobLeaveHandler(),
                reject: () => {}
              })
            }}
          />
        )}
      </div>
      <div className="job-desc">
        <div className="job-company-photo">
          <Tooltip
            title={owner.name || ''}
            interactive
            placement="top"
            onClick={() => {
              clipboardCopy(owner.name || '')
              toastRef.current.show({
                severity: 'info',
                summary: 'Copied to Clipboard!',
                life: 2000
              })
            }}
          >
            <span className="pi pi-users"></span>
          </Tooltip>
          <PhotoFrame
            newPhoto={company.logo}
            editable={false}
            getCurrentPhoto={(e) => {}}
            onClick={() => {
              history.push({
                pathname: '/unternehmen',
                search: `?searchParams=${company.name}`
              })
            }}
          />
        </div>
        <p className="job-region">{region.name}</p>
        <p className="job-salary">
          {salary} {currency}
        </p>
      </div>

      <div className="candidates-container">
        <div className="candidates-list">
          <Slider {...Product4} className="">
            {candidates.map((candidate, idx) => {
              if (candidate.owner.name || candidate.details.name) {
                return (
                  <div className="citem-container">
                    <div className="candidates-item">
                      <div className="candidate-stage">
                        <Tooltip
                          title={candidate.owner.name || ''}
                          interactive
                          placement="top"
                          onClick={() => {
                            clipboardCopy(candidate.owner.name || '')
                            toastRef.current.show({
                              severity: 'info',
                              summary: 'Copied to Clipboard!',
                              life: 2000
                            })
                          }}
                        >
                          <span className="pi pi-users"></span>
                        </Tooltip>
                        {candidate.stage != '-1' && (
                          <Tooltip
                            title={candidateStage(candidate.stage)}
                            interactive
                            placement="top"
                            onClick={() => {
                              clipboardCopy(candidateStage(candidate.stage))
                              toastRef.current.show({
                                severity: 'info',
                                summary: 'Copied to Clipboard!',
                                life: 2000
                              })
                            }}
                          >
                            <span
                              className="stage"
                              onClick={(e) => {
                                setJobUpdateModal(candidate)
                                op.current[`${jobIndex}-${idx}`].toggle(e)
                              }}
                            >
                              {candidateStage(candidate.stage)}
                            </span>
                          </Tooltip>
                        )}
                      </div>
                      <div className="candidate-profile">
                        <PhotoFrame
                          className={
                            candidate.owner._id === user._id ? 'owner' : ''
                          }
                          newPhoto={candidate.details.photo}
                          editable={false}
                          getCurrentPhoto={(e) => {}}
                          onClick={() => {
                            history.push({
                              pathname: '/kandidaten',
                              search: `?searchParams=${candidate.details.name}`
                            })
                          }}
                        />
                        <p
                          className={`candidate-name ${
                            candidate.owner._id === user._id
                              ? 'candidate-name-owner'
                              : ''
                          }`}
                          onClick={() => {
                            history.push({
                              pathname: '/kandidaten',
                              search: `?searchParams=${candidate.details.name}`
                            })
                          }}
                        >
                          {candidate.details.name}
                        </p>
                        {['-1', '1', '2', '3'].includes(candidate.stage) && (
                          <p
                            className="candidate-appointment-datetime"
                            onClick={(e) => {
                              setJobUpdateModal(candidate)
                              op.current[`${jobIndex}-${idx}`].toggle(e)
                            }}
                          >
                            {candidate.stage == '-1'
                              ? '+'
                              : `${
                                  candidate.meetDate
                                    ? moment(candidate.meetDate).format(
                                        'DD.MM.YYYY'
                                      )
                                    : ''
                                } ${
                                  candidate.meetTime
                                    ? moment(
                                        candidate.meetTime,
                                        'HH:mm'
                                      ).format('HH:mm')
                                    : ''
                                }`}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )
              }
              return null
            })}
          </Slider>
        </div>
      </div>
      {candidates.map((candidate, idx) => {
        if (candidate.owner.name || candidate.details.name) {
          return (
            <UpdateStageModal
              toggle={(e) => {
                op.current[`${jobIndex}-${idx}`].hide(e)
              }}
              setRef={(ref) => {
                op.current[`${jobIndex}-${idx}`] = ref
              }}
              candidate={candidate}
              authToken={authToken}
              user={user}
              jobId={_id}
              index={idx}
              company={company}
              jobItem={{
                assistants,
                owner,
                region,
                salary,
                title,
                _id
              }}
            />
          )
        }
      })}
      {jobModal.visible && (
        <AddCustomerModal
          visible={jobModal.visible}
          setModal={setJobModal}
          candidate={jobModal.candidate}
          authToken={authToken}
          user={user}
          jobId={_id}
        />
      )}
      <Toast ref={toastRef} position="top-left" />
    </div>
  )
}

export default jobItem
