/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { OverlayPanel } from 'primereact/overlaypanel'
import { GiPodiumWinner } from 'react-icons/gi'
import { BsCalendar2Week } from 'react-icons/bs'
import { BiTime } from 'react-icons/bi'
import { Dropdown } from 'primereact/dropdown'
import { stages } from '../../utils/stages'
import { Calendar } from 'primereact/calendar'
import moment from 'moment'
import { Button } from 'primereact/button'
import { useDispatch, useSelector } from 'react-redux'
import { Creators as userActions } from '../../redux/ducks/users'
import { Creators as profitCenterActions } from '../../redux/ducks/profitCenter'
import { confirmDialog } from 'primereact/confirmdialog'
import { useHistory } from 'react-router-dom'

// const stages = [{
//     id: '-1',
//     name: 'Blank'
// }, {
//     id: '0',
//     name: 'Send'
// }, {
//     id: '1',
//     name: 'I'
// }, {
//     id: '2',
//     name: 'II'
// }, {
//     id: '3',
//     name: 'III'
// }, {
//     id: '4',
//     name: 'Deal'
// }, {
//     id: '-2',
//     name: 'Rejected'
// }]

const UpdateStageModal = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    toggle,
    candidate,
    authToken,
    user,
    jobId,
    setRef,
    style,
    company,
    jobItem
  } = props

  const [loading, setLoading] = useState(false)
  const [candidateForm, setCandidateForm] = useState({
    meetDate: { value: null, valid: true },
    meetTime: { value: null, valid: true },
    stage: { value: '-1', valid: true }
  })

  useEffect(() => {
    if (candidate && candidate._id) {
      setCandidateForm({
        meetDate: {
          value: candidate.meetDate
            ? moment(candidate.meetDate).toDate()
            : null,
          valid: true
        },
        meetTime: {
          value: candidate.meetTime
            ? moment(candidate.meetTime, 'HH:mm').toDate()
            : null,
          valid: true
        },
        stage: { value: candidate.stage, valid: true }
      })
    }
  }, [candidate])

  const formInputHandler = (event) => {
    setCandidateForm((prev) => {
      prev[event['name']] = {
        value: event['value'],
        valid: true
      }
      return { ...prev }
    })
  }

  const validateForm = () => {
    let isValid = true
    let form = JSON.parse(JSON.stringify(candidateForm))

    if (!form.stage.value) {
      form.stage.valid = false
      isValid = false
    }

    // if (['1', '2', '3'].includes(form.stage.value)) {
    //     if (!form.meetDate.value) {
    //         form.meetDate.valid = false;
    //         isValid = false;
    //     }

    //     if (!form.meetTime.value) {
    //         form.meetTime.valid = false;
    //         isValid = false;
    //     }
    // }

    if (!isValid) {
      setCandidateForm(form)
    }

    return isValid
  }

  const updateCandidateHandler = (event) => {
    if (validateForm()) {
      if (
        candidate.stage !== candidateForm.stage.value &&
        candidateForm.stage.value == 4
      ) {
        if (
          company.deal &&
          company.deal.taxNumber &&
          company.deal.invoiceEmail
        ) {
          confirmDialog({
            className: 'basic-salary-cdialog w-50p',
            message:
              'Es wird ein Scan des unterschriebenen Arbeitsvertrags benötigt, sowie das Startgehalt und das Startdatum des Kandidaten.',
            header: 'Soll der Deal tatsächlich verbindlich eingebucht werden?',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Ja, eintragen',
            rejectLabel: 'Abbrechen',
            accept: () => {
              dispatch(
                profitCenterActions.initializeDeal(company, candidate, jobItem)
              )
              history.push('/profitcenter')
            },
            reject: () => {}
          })
          return
        }

        confirmDialog({
          className: 'basic-salary-cdialog w-50p',
          message:
            'Um den Deal einzutragen müssen die Rechnungsdaten im Vorfeld beim Unternehmen hinterlegt werden.',
          header: 'Rechnungsdaten fehlen',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Rechnungsdaten hinterlegen',
          rejectLabel: 'Abbrechen',
          accept: () => {
            history.push({
              pathname: '/unternehmen',
              search: `?searchParams=${company.name}`
            })
          },
          reject: () => {}
        })
        return
      }

      setLoading(true)
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

      dispatch(
        userActions.updateCandidateInsideJob(
          jobId,
          {
            candidateId: candidate._id,
            stage: candidateForm.stage.value,
            meetDate:
              candidateForm.meetDate.value &&
              moment(candidateForm.meetDate.value).format('YYYY-MM-DD'),
            meetTime:
              candidateForm.meetTime.value &&
              moment(candidateForm.meetTime.value).format('HH:mm'),
            timeZone: timeZone
          },
          authToken,
          user
        )
      )
        .then((res) => {
          setLoading(false)
          toggle(event)
        })
        .catch((err) => {
          setLoading(false)
        })
    }
  }

  return (
    <OverlayPanel
      className="cpc-modal"
      ref={(ref) => {
        setRef(ref)
      }}
      id="overlay_panel"
      style={style}
    >
      <div className="p-grid">
        <p className="p-mt-0">What is the next stage?</p>
        <div className="p-field">
          <GiPodiumWinner />
          <Dropdown
            className={!candidateForm.stage.valid ? 'p-invalid w-100' : 'w-100'}
            value={candidateForm.stage.value}
            options={stages}
            onChange={(e) => {
              formInputHandler({
                name: 'stage',
                value: e.value
              })
              formInputHandler({
                name: 'meetDate',
                value: null
              })
              formInputHandler({
                name: 'meetTime',
                value: null
              })
            }}
            // filter
            optionValue="id"
            optionLabel="name"
            filterBy="name"
            placeholder="Select"
          />
        </div>
        {['1', '2', '3'].includes(candidateForm.stage.value) && (
          <>
            <div className="p-field">
              <BsCalendar2Week />
              <Calendar
                id="time12"
                dateFormat="dd.mm.yy"
                className={
                  !candidateForm.meetDate.valid ? 'p-invalid w-100' : 'w-100'
                }
                value={
                  candidateForm.meetDate.value &&
                  moment(candidateForm.meetDate.value).toDate()
                }
                onChange={(e) =>
                  formInputHandler({
                    name: 'meetDate',
                    value: e.value
                  })
                }
              />
            </div>
            <div className="p-field ">
              <BiTime />
              <Calendar
                id="time12"
                className={
                  !candidateForm.meetTime.valid ? 'p-invalid w-100' : 'w-100'
                }
                value={
                  candidateForm.meetTime.value &&
                  moment(candidateForm.meetTime.value).toDate()
                }
                onChange={(e) =>
                  formInputHandler({
                    name: 'meetTime',
                    value: e.value
                  })
                }
                timeOnly
                hourFormat="24"
              />
            </div>
          </>
        )}
        <div className="btn-container">
          <Button
            label="cancel"
            className="p-button-text p-pl-0"
            onClick={(e) => toggle(e)}
          />
          <button
            className={`p-button-text label-bold p-pr-0 p-button p-component ${
              loading &&
              'p-disabled p-button-loading p-button-loading-label-only p-button-loading-left'
            }`}
            disabled={loading}
            onClick={updateCandidateHandler}
          >
            {loading && (
              <span className="p-button-icon p-c p-button-loading-icon pi pi-spinner pi-spin p-button-icon-left"></span>
            )}
            <span className="p-button-label p-c">enter</span>
          </button>
          {/* <Button label="enter" loading className={`p-button-text label-bold p-pr-0`} onClick={updateCandidateHandler} /> */}
        </div>
      </div>
    </OverlayPanel>
  )
}

export default UpdateStageModal
