/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { useDispatch, useSelector } from 'react-redux'
import AsyncSelect from 'react-select/async'

import { Creators as candidateRadarActions } from '../../redux/ducks/candidateRadar'
import { Creators as candidatesActions } from '../../redux/ducks/candidates'

const AddCustomerRadarModal = (props) => {
  const dispatch = useDispatch()

  const { visible, setModal, authToken, user } = props

  const candidates = useSelector((state) => state.candidatesReducer.candidates)
  const candidateIdentites = useSelector(
    (state) => state.candidatesReducer.candidateIdentites
  )

  const [isLoading, setIsLoading] = useState(false)
  const [default20, setDefault20] = useState(true)
  const [candidateForm, setCandidateForm] = useState({
    candidateId: { value: null, valid: true }
  })

  const formInputHandler = (event) => {
    setCandidateForm((prev) => {
      prev[event['name']] = {
        value: event['value'],
        valid: true
      }
      return { ...prev }
    })
  }

  const validateForm = () => {
    let isValid = true
    let form = JSON.parse(JSON.stringify(candidateForm))

    if (!form.candidateId.value) {
      form.candidateId.valid = false
      isValid = false
    }

    if (!isValid) {
      setCandidateForm(form)
    }

    return isValid
  }

  const formSubmitHanlder = () => {
    if (validateForm()) {
      setIsLoading(true)
      dispatch(
        candidateRadarActions.addCandidate(
          candidateForm.candidateId.value,
          user,
          authToken
        )
      )
        .then((res) => {
          setIsLoading(false)
          setModal(false)
        })
        .catch((err) => {
          setIsLoading(false)
        })
    }
  }

  const promiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      dispatch(
        candidatesActions.getAllCandidatesIdentitiesList(
          authToken,
          1,
          inputValue,
          20,
          false
        )
      )
        .then((res) => {
          setDefault20(false)
          resolve(res)
        })
        .catch((err) => {
          setDefault20(true)
          resolve(candidateIdentites)
        })
    })
  }

  const renderFooter = () => {
    return (
      <div className="footer-btn-container">
        <Button
          label="Abbrechen"
          onClick={() => setModal(false)}
          className="p-button-text"
        />
        <button
          className={
            isLoading
              ? 'p-button p-component p-button-loading p-button-loading-label-only p-button-loading-left p-button-cb p-disabled'
              : 'p-button p-component p-button-loading p-button-loading-label-only p-button-loading-left p-button-cb '
          }
          onClick={() => formSubmitHanlder()}
        >
          {isLoading && (
            <span className="p-button-icon p-c p-button-loading-icon pi pi-spinner pi-spin p-button-icon-left "></span>
          )}
          <span className="p-button-label p-c">Speichern</span>
        </button>
      </div>
    )
  }

  return (
    <Dialog
      header={'Add Candidates'}
      visible={visible}
      closable={!isLoading}
      position={'center'}
      modal
      style={{ width: '50vw' }}
      footer={renderFooter()}
      onHide={() => setModal(!visible)}
      draggable={false}
      resizable={false}
    >
      <div className="basic-sal-form-container">
        <div className="p-field ">
          <label for="jobTitle">Candidate: </label>
          <AsyncSelect
            placeholder="Search Field"
            classNamePrefix={'rs-dd'}
            className={
              !candidateForm.candidateId.valid
                ? 'p-invalid w-100 rs-dd'
                : 'w-100 rs-dd'
            }
            value={
              default20
                ? candidates.find(
                    (c) => c._id === candidateForm.candidateId.value
                  )
                : candidateIdentites.find(
                    (c) => c._id === candidateForm.candidateId.value
                  )
            }
            onChange={(e) => {
              formInputHandler({
                name: 'candidateId',
                value: e._id
              })
            }}
            defaultOptions={candidates || []}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option._id}
            loadOptions={promiseOptions}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default AddCustomerRadarModal
