/* eslint-disable */
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { FaRegHandshake, FaUserCircle } from 'react-icons/fa'
import { RiPulseFill } from 'react-icons/ri'
import { MdEuroSymbol } from 'react-icons/md'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'
import Pagination from '@material-ui/lab/Pagination'
import { ProgressBar } from 'primereact/progressbar'
import { TabView, TabPanel } from 'primereact/tabview'
import { Dropdown } from 'primereact/dropdown'
import { Creators as userActions } from '../../redux/ducks/users'
import { Creators as candidateRadarActions } from '../../redux/ducks/candidateRadar'
import { Creators as authActions } from '../../redux/ducks/auth'
import { Creators as candidatesActions } from '../../redux/ducks/candidates'
import { Creators as addDataPanelActions } from '../../redux/ducks/addDataPanel'
import moment from 'moment'
import { Rating } from 'primereact/rating'
import JobItem from './jobItem'
import { ProgressSpinner } from 'primereact/progressspinner'
import countries from '../../utils/currency.json'
import { FiUserPlus } from 'react-icons/fi'
import { clipboardCopy } from '../../helpers/clipboardUtils'
import { useHistory } from 'react-router-dom'
import { AiOutlineClose } from 'react-icons/ai'
import { Tooltip } from '@material-ui/core'
import PhotoFrame from '../PhotoFrame/PhotoFrame'
import AddCustomerRadarModal from './addCustomerRadarModal'
import { confirmDialog } from 'primereact/confirmdialog'
import { Toast } from 'primereact/toast'
import { GiRadarSweep } from 'react-icons/gi'

function InfoUser({ auth, usersState, getAllUsers, authState, authLogout }) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const history = useHistory()
  const dispatch = useDispatch()

  const toastRef = useRef(null)

  const code = authState.user.code
  const jobList = useSelector((state) => state.usersReducer.userJobsList.list)
  const jobListCount = useSelector(
    (state) => state.usersReducer.userJobsList.totalCount
  )
  const userIdentites = useSelector((state) => state.usersReducer.userIdentites)
  const filterUser = useSelector((state) => state.usersReducer.adminUserFilter)

  const userProtocolCount = useSelector(
    (state) => state.usersReducer.userProtocols.total
  )
  const activitesList = useSelector(
    (state) => state.usersReducer.userProtocols.users
  )
  const activiestCount = useSelector(
    (state) => state.usersReducer.userProtocols.usersPageCount
  )
  const candidateRadars = useSelector(
    (state) => state.candidateRadarReducer.candidatesList.list
  )

  // const [userId, setUserId] = useState(null)
  const [loading, setLoading] = useState(true)
  const [activitesLoading, setActivitesLoading] = useState(true)
  const [radarLoading, setRadarLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [activityPage, setActivityPage] = useState(1)
  const [countryList, setCountryList] = useState([])
  const [activeIndex1, setActiveIndex1] = useState(0)
  const [stateChanger, setstateChanger] = useState(false)
  const [activeFilter, setActiveFilter] = useState('month')

  const [candidateRadar, setCandidateRadar] = useState(false)

  useEffect(() => {
    setCountryList(countries)
    dispatch(candidatesActions.getCandidates('', auth.token))
    getRadarCandidates(1)
    // setUserId(authState.user._id)
  }, [])

  // useEffect(() => {
  //   let test = setTimeout(() => {
  //     setstateChanger(stateChanger ? false : true)
  //     clearTimeout(test);
  //   }, 5000);

  //   return () => clearTimeout(test);
  // }, [stateChanger])

  useEffect(() => {
    getActivites(1, 20)
  }, [activeFilter])

  useEffect(() => {
    setTimeout(() => {
      activeIndex1 === 2 ? setstateChanger(true) : setstateChanger(false)
    }, 100)
  }, [activeIndex1])

  useEffect(() => {
    getPerticularUserJobHander(filterUser || auth.user._id)
  }, [filterUser])

  const getUsersJob = (pageNumber, limit, userId) => {
    dispatch(userActions.getUsersJobList(auth.token, pageNumber, limit, userId))
      .then((res) => {
        setPage(pageNumber)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const getUserActivites = (pageNumber, limit) => {
    dispatch(
      userActions.findProtocolsCount(
        auth.token,
        timeZone,
        activeFilter,
        pageNumber,
        limit
      )
    )
      .then((res) => {
        setActivityPage(pageNumber)
        setActivitesLoading(false)
        setTimeout(() => {
          if (activeIndex1 === 2) setstateChanger(true)
        }, 100)
      })
      .catch((err) => setActivitesLoading(false))
  }

  const getCandidateRadar = (pageNumber, limit) => {
    dispatch(candidateRadarActions.getCandidatesList(limit, auth.token))
      .then((res) => {
        setRadarLoading(false)
      })
      .catch((err) => {
        setRadarLoading(false)
      })
  }

  const candiateRemoveHandler = (candidateId) => {
    dispatch(candidateRadarActions.deleteCandidate(candidateId, auth.token))
      .then((res) => {})
      .catch((err) => {})
  }

  const getJobs = (pageNumber) => {
    setLoading(true)
    getUsersJob(pageNumber, 20)
  }

  const getActivites = (pageNumber) => {
    setstateChanger(false)
    setActivitesLoading(true)
    getUserActivites(pageNumber, 20)
  }

  const getRadarCandidates = (pageNumber) => {
    setRadarLoading(true)
    getCandidateRadar(pageNumber, 100)
  }

  const getPerticularUserJobHander = (userId) => {
    setLoading(true)
    getUsersJob(page, 20, userId)
  }

  const calculateMaxProtocolsPercentage = (key, value) => {
    switch (key) {
      case 'yesterday':
        return (value / 30) * 100
      case 'month':
        return (value / (30 * 20)) * 100
      case '1month':
        return (value / (30 * 20)) * 100
      default:
        return (value / 30) * 100
    }
  }
  return (
    <div className="Container card p-gird-container" id="dash_board">
      <div className="p-grid upper-content">
        <div className="p-col-2 p-md-3 p-sm-4 p-lg-2 p-col logoFrame p-d-flex p-jc-center">
          <picture className="box">
            {usersState.users
              .filter((value) => {
                if (value.code === code) {
                  return value
                } else {
                  return null
                }
              })
              .map((val, key) => {
                return (
                  <img
                    className="box images"
                    id="img"
                    key={key}
                    src={val.photo}
                  />
                )
              })}
          </picture>
        </div>
        <div className="p-col-10 p-md-9 p-sm-8 p-lg-10">
          <div className="p-grid tabss">
            <div className="p-col-4 p-md-4 p-lg-4">
              <div className="tab-container">
                <div
                  className={`tabItem ${activeIndex1 === 0 && 'active'}`}
                  onClick={() => setActiveIndex1(0)}
                >
                  <FaRegHandshake className="icon_Info" />
                  <span>Job Interviews</span>
                </div>
              </div>
            </div>
            <div className="p-col-4 p-md-4 p-lg-4">
              <div className="tab-container p-center">
                <div
                  className={`tabItem ${activeIndex1 === 1 && 'active'}`}
                  onClick={() => setActiveIndex1(1)}
                >
                  <GiRadarSweep size={22} className="icon_Info" />
                  <span>Kandidaten Radar</span>
                </div>
              </div>
            </div>
            <div className="p-col-4 p-md-4 p-lg-4">
              <div className="tab-container p-end">
                <div
                  className={`tabItem ${activeIndex1 === 2 && 'active'}`}
                  onClick={() => setActiveIndex1(2)}
                >
                  <RiPulseFill className="icon_Info" />
                  <span>Activities</span>
                </div>
              </div>
            </div>
            <div className="p-col-12 p-md-12 p-lg-12">
              <div className="pp-bar">
                <ProgressBar
                  value={
                    loading
                      ? 0
                      : calculateMaxProtocolsPercentage(
                          'yesterday',
                          userProtocolCount
                        )
                  }
                ></ProgressBar>
                <p className="progress-count">{userProtocolCount}/30</p>
              </div>
            </div>
          </div>
        </div>

        {auth.user.admin && activeIndex1 == 0 && (
          <div className="p-col-12 p-md-12 p-lg-12">
            <div className="dashboard-dd-container">
              <Dropdown
                className="dashboard-dd"
                value={filterUser || auth.user._id}
                options={userIdentites}
                onChange={(e) => {
                  dispatch(userActions.adminFilterUser(e.value))
                }}
                optionLabel="name"
                optionValue="_id"
                // filter
                // showClear
                // filterBy="name"
                placeholder="Für welchen Kandidaten"
                // itemTemplate={candidateOptionTemplate}
              />
            </div>
          </div>
        )}

        {/* <div className="p-col-9 p-md-9 p-lg-9">
          <ProgressBar value={50}></ProgressBar>
        </div> */}

        {/* <div className="p-col-4 p-md-3 p-lg-3">
          <div className="box">
            <div className="nameBox">
              <PersonIcon className="icon_Info" />
              <h4 id="h4" className="username" style={{ marginLeft: '10px' }}>
                {' '}
                {authState.user.name}{' '}
              </h4>
            </div>
            <div className="dataOfHome" id="Offene">
              <WhatshotIcon className="icon_Info" />

              <h4 id="h4" style={{ marginLeft: '10px' }}>
                {' '}
                Job Interviews
              </h4>
            </div>
          </div>
        </div>
        <div className="p-col-4 p-md-3 p-lg-3">
          <div className="box ">
            <div id="middle_div">
              <div className="stars ">
                {auth.user.admin ? (
                  <Rating
                    id="star_rating"
                    stars={10}
                    value={val2}
                    cancel={false}
                    onChange={(e) => setVal2(e.value)}
                  />
                ) : (
                  <Rating
                    id="star_rating"
                    stars={10}
                    value={val2}
                    cancel={false}
                  />
                )}
              </div>
              <div className="dataOfHome" id="Top_Kandidaten">
                <GroupIcon className="icon_Info" />
                <h4 id="h4" style={{ marginLeft: '5px' }}>
                  {' '}
                  Activities
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="p-col-4 p-md-3 p-lg-3">
          <div className="box">
            <div id="last_div">
              <h4></h4>
              <div className="dataOfHome">
                <DescriptionIcon />
                <h4 id="h4" style={{ marginLeft: '10px' }}>
                  Profit Center
                </h4>
              </div>
            </div>
          </div>
        </div> */}
      </div>

      <TabView
        className="dash-tabview"
        activeIndex={activeIndex1}
        onTabChange={(e) => setActiveIndex1(e.index)}
      >
        <TabPanel>
          <div className="job-list-container">
            {loading ? (
              <div className="loading-container p-text-center p-pt-5">
                <ProgressSpinner />
              </div>
            ) : (
              <>
                {jobList &&
                  jobList.map((jb, jIdx) => {
                    return (
                      <JobItem
                        key={`job-item-${jIdx}`}
                        jobItem={jb}
                        authToken={auth.token}
                        user={auth.user}
                        currency={countryList[jb.currency]['symbol']}
                        jobIndex={jIdx}
                      />
                    )
                  })}
              </>
            )}
            {jobListCount > 20 ? (
              <Pagination
                className="jobs-pagination"
                page={page}
                count={Math.ceil(jobListCount / 20)}
                siblingCount={2}
                boundaryCount={1}
                shape={'rounded'}
                showFirst={true}
                showLastButton={true}
                onChange={(event, value) => {
                  getJobs(value)
                }}
              />
            ) : (
              !loading &&
              jobListCount <= 0 && (
                <p className="p-text-center">No Jobs Found</p>
              )
            )}
          </div>
        </TabPanel>
        <TabPanel>
          <div className="candidate-radar-container">
            <div className="p-grid add-container">
              <div className="p-sm-12 p-xs-12 p-md-6 p-lg-6 p-xl-6"></div>
              <div className="p-sm-12 p-xs-12 p-md-6 p-lg-6 p-xl-6">
                <div className="add-btn-container">
                  <FiUserPlus
                    className="add-btn"
                    onClick={() => {
                      setCandidateRadar(true)
                    }}
                  />
                </div>
              </div>
            </div>
            {radarLoading ? (
              <div className="loading-container p-text-center p-pt-5">
                <ProgressSpinner />
              </div>
            ) : (
              <div className="p-grid candidate-list-container">
                {candidateRadars &&
                  candidateRadars.map((candidate) => {
                    let candidatePosition =
                      candidate?.candidate?.profilePosition?.replace('<p>', '')
                    candidatePosition = candidatePosition?.replace('</p>', '')

                    let candidateRegion =
                      candidate?.candidate?.profileRegion?.replace('<p>', '')
                    candidateRegion = candidateRegion?.replace('</p>', '')

                    return (
                      <div className="p-sm-6 p-xs-12 p-md-4 p-lg-3 p-xl-3">
                        <div className="candidate-profile">
                          <div className="action-container">
                            <Tooltip
                              title={candidate?.owner.name}
                              interactive
                              placement="top"
                              onClick={() => {
                                clipboardCopy(candidate?.owner.name)
                                toastRef.current.show({
                                  severity: 'info',
                                  summary: 'Copied to Clipboard!',
                                  life: 2000
                                })
                              }}
                            >
                              <span className="pi pi-users"></span>
                            </Tooltip>
                            {(candidate.owner._id === authState.user._id ||
                              auth.user.admin) && (
                              <AiOutlineClose
                                className="rmv-btn"
                                onClick={() => {
                                  confirmDialog({
                                    className: 'basic-salary-cdialog',
                                    message:
                                      'Möchten Sie diesen Kandidaten wirklich entfernen?',
                                    header: '',
                                    icon: 'pi pi-exclamation-triangle',
                                    acceptLabel: 'Sicher',
                                    rejectLabel: 'Abbrechen',
                                    accept: () =>
                                      candiateRemoveHandler(candidate._id),
                                    reject: () => {}
                                  })
                                }}
                              />
                            )}
                          </div>
                          <PhotoFrame
                            className={''}
                            newPhoto={candidate?.candidate?.photo}
                            editable={false}
                            getCurrentPhoto={(e) => {}}
                            onClick={() => {
                              history.push({
                                pathname: '/kandidaten',
                                search: `?searchParams=${candidate?.candidate?.name}`
                              })
                            }}
                          />
                          <p
                            className={`candidate-name ${candidate.code}`}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              history.push({
                                pathname: '/kandidaten',
                                search: `?searchParams=${candidate?.candidate?.name}`
                              })
                            }}
                          >
                            {candidate?.candidate?.name}
                          </p>
                          <p className="candidate-position make-bold">
                            {candidatePosition || '...'}
                          </p>
                          <p className="candidate-position">
                            {candidateRegion || '...'}
                          </p>
                        </div>
                      </div>
                    )
                  })}
              </div>
            )}
            {candidateRadar && (
              <AddCustomerRadarModal
                visible={candidateRadar}
                setModal={setCandidateRadar}
                authToken={auth.token}
                user={authState.user}
              />
            )}
          </div>
        </TabPanel>
        <TabPanel>
          <div className="activites-container">
            <>
              <div className="p-grid">
                <div className="p-sm-12 p-xs-12 p-md-6 p-lg-6 p-xl-6"></div>
                <div className="p-sm-12 p-xs-12 p-md-6 p-lg-6 p-xl-6">
                  <div className="toggle-button-group">
                    <p
                      className={activeFilter === 'month' && 'active'}
                      onClick={() => {
                        setActiveFilter('month')
                      }}
                    >
                      Monat
                    </p>
                    <span>/</span>
                    <p
                      className={activeFilter === '1month' && 'active'}
                      onClick={() => {
                        setActiveFilter('1month')
                      }}
                    >
                      Vormonat
                    </p>
                    <span>/</span>
                    <p
                      className={activeFilter === 'yesterday' && 'active'}
                      onClick={() => {
                        setActiveFilter('yesterday')
                      }}
                    >
                      {moment().subtract(1, 'days').format('DD.MM.YYYY')}
                    </p>
                  </div>
                </div>
              </div>
              {activitesLoading ? (
                <div className="loading-container p-text-center p-pt-5">
                  <ProgressSpinner />
                </div>
              ) : (
                <div className="list-container">
                  <ul>
                    {activitesList.map((item) => {
                      return (
                        <li>
                          <picture
                            className={
                              auth.user._id === item._id ? 'box active' : 'box'
                            }
                          >
                            <img
                              className="box images"
                              id="img"
                              key={'key'}
                              src={item.photo}
                            />
                          </picture>
                          <div className="usr-item">
                            <p className="title">{item.name}</p>
                            <div className="pp-usr-bar">
                              <ProgressBar
                                value={
                                  stateChanger
                                    ? calculateMaxProtocolsPercentage(
                                        activeFilter,
                                        item.count
                                      )
                                    : 0
                                }
                              ></ProgressBar>
                              <p className="progress-count">
                                {item.count}/
                                {activeFilter === 'yesterday'
                                  ? 30
                                  : activeFilter === 'month'
                                  ? 30 * 20
                                  : activeFilter === '1month'
                                  ? 30 * 20
                                  : 30}
                              </p>
                            </div>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )}
            </>
            {activiestCount > 20 ? (
              <Pagination
                className="jobs-pagination"
                page={activityPage}
                count={Math.ceil(activiestCount / 20)}
                siblingCount={2}
                boundaryCount={1}
                shape={'rounded'}
                showFirst={true}
                showLastButton={true}
                onChange={(event, value) => {
                  getActivites(value)
                }}
              />
            ) : (
              !activitesLoading &&
              activitesList <= 0 && (
                <p className="p-text-center">No Activites Available</p>
              )
            )}
          </div>
        </TabPanel>
      </TabView>
      <Toast ref={toastRef} position="top-left" />
    </div>
  )
}

InfoUser.propTypes = {
  auth: PropTypes.object.isRequired,
  usersState: PropTypes.object.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  authState: PropTypes.object.isRequired,
  authLogout: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.authReducer,
  usersState: state.usersReducer,
  authState: state.authReducer
})

const mapDispatchToProps = (dispatch) => ({
  getAllUsers: (token) => dispatch(userActions.getAllUsers(token)),
  authLogout: (token) => dispatch(authActions.logout(token)),
  addDataPanelOpen: (path) => dispatch(addDataPanelActions.openPanel(path))
})

export default connect(mapStateToProps, mapDispatchToProps)(InfoUser)
