import { Creators as messages } from './statusMessages'

// Action types
const Types = {
  LOAD_TAGS: 'tags/LOAD_TAGS',
  LOAD_SUCCESS: 'tags/LOAD_SUCCESS',
  LOAD_FAIL: 'tags/LOAD_FAIL',
  ADD_TAG: 'tags/ADD_TAG',
  UPDATE_TAG: 'tags/UPDATE_TAG',
  DELETE_TAG: 'tags/DELETE_TAG',
  LOAD_FILTERED_TAGS: 'filterTags/LOAD_FILTERED_TAGS',
  LOAD_JOB_TAGS: 'tags/LOAD_JOB_TAGS',
  SHOW_NEW_TAG_KATEGORIE: 'filterTags/SHOW_NEW_TAG_KATEGORIE',
  TAG_LIST_LOADING_REQUEST: 'tags/TAG_LIST_LOADING_REQUEST',
  TAG_LIST_LOADING_SUCCESS: 'tags/TAG_LIST_LOADING_SUCCESS',
  TAG_LIST_LOADING_FAIL: 'tags/TAG_LIST_LOADING_FAIL'
}

const INITIAL_STATE = {
  tags: [],
  key: '',
  addTag: {},
  catKey: '',
  updatedTag: {
    _id: '',
    name: '',
    code: ''
  },
  showNewTag: false,
  tagList: {
    list: [],
    totalCount: 0
  },
  jobTags: []
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LOAD_TAGS:
      return {
        ...state,
        tags: action.payload.tags.map((tag) => tag)
      }

    case Types.ADD_TAG:
      return {
        ...state,
        addTag: action.payload
      }

    case Types.UPDATE_TAG:
      return {
        ...state,
        tagList: {
          ...state.tagList,
          list: state.tagList.list.map((e) => {
            if (e._id === action.payload._id) {
              return action.payload
            } else return e
          })
        }
      }

    case Types.DELETE_TAG:
      return {
        ...state,
        tagList: {
          list: state.tagList.list.filter((tag) => tag._id !== action.payload),
          count: state.tagList.count - 1
        }
      }
    case Types.TAG_LIST_LOADING_SUCCESS:
      return {
        ...state,
        tagList: {
          list:
            action.payload.data && action.payload.data.length > 0
              ? [...action.payload.data]
              : state.tagList.list,
          totalCount:
            action.payload.data && action.payload.data.length > 0
              ? action.payload.totalCount
              : state.tagList.totalCount
        },
        isLoading: false
      }
    case Types.LOAD_FILTERED_TAGS:
      return {
        ...state,
        key: action.payload.key,
        catKey: action.payload.catKey
      }
    case Types.LOAD_JOB_TAGS:
      return {
        ...state,
        jobTags: [...action.payload.data]
      }

    case Types.SHOW_NEW_TAG_KATEGORIE:
      return {
        ...state,
        showNewTag: !state.showNewTag
      }

    default:
      return state
  }
}

export const Creators = {
  getAllTags: (token) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/tag/all`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const tags = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.LOAD_TAGS,
            payload: tags
          })
        } else {
          dispatch(
            messages.setMessage({
              status: 404,
              message: tags.message
            })
          )
        }
      } catch (error) {
        dispatch(messages.defaultNetworkError('Loading Tags...'))
      }
    }
  },

  addTag: (newTag, token) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/tag`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify(newTag)
          }
        )

        const result = await response.json()
        if (response.ok) {
          newTag.id = result.tagId
          dispatch({
            type: Types.ADD_TAG,
            payload: newTag
          })
        } else {
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.error.message
            })
          )
        }
        return response.ok
      } catch (error) {
        dispatch(messages.defaultNetworkError())
      }
    }
  },

  updateTag: (editedTag, token) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/tag`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify(editedTag)
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.UPDATE_TAG,
            payload: editedTag
          })
        }
      } catch (error) {
        dispatch(messages.defaultNetworkError())
      }
    }
  },

  deleteTag: (tagId, token) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/tag/${tagId}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )

        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.DELETE_TAG,
            payload: tagId
          })
        } else {
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        }
      } catch (error) {
        dispatch(messages.defaultNetworkError())
      }
    }
  },

  getFilterTags: (key, catKey) => {
    return (dispatch) => {
      dispatch({
        type: Types.LOAD_FILTERED_TAGS,
        payload: { key, catKey }
      })
    }
  },

  showHideTags: () => {
    return (dispatch) => {
      dispatch({ type: Types.SHOW_NEW_TAG_KATEGORIE })
    }
  },
  getTagsList: (
    token,
    page = 1,
    search = { name: '', code: '' },
    sortOrder,
    limit = 1000
  ) => {
    return async (dispatch) => {
      dispatch({
        type: Types.TAG_LIST_LOADING_REQUEST
      })
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/tag/getTags`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify({
              search,
              page,
              sortOrder,
              limit
            })
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.TAG_LIST_LOADING_SUCCESS,
            payload: result.result
          })
        } else if (response.status === 401) {
          dispatch({
            type: Types.TAG_LIST_LOADING_FAIL
          })
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else {
          dispatch({
            type: Types.TAG_LIST_LOADING_FAIL
          })
        }
      } catch (error) {
        console.log(error)
        dispatch({
          type: Types.TAG_LIST_LOADING_FAIL
        })
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  getJobTags: (token) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/tag/getTags`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify({
              search: {
                name: ['region'],
                code: ''
              },
              page: 1,
              sortOrder: {},
              limit: 1000
            })
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.LOAD_JOB_TAGS,
            payload: result.result
          })
        } else if (response.status === 401) {
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        }
      } catch (error) {
        console.log(error)
        dispatch({
          type: Types.TAG_LIST_LOADING_FAIL
        })
        dispatch(messages.defaultNetworkError())
      }
    }
  }
}
