import { Creators as messages } from './statusMessages'

// Action types
export const Types = {
  UPLOAD_PDF: 'pdf/UPLOAD_PDF',
  FETCH_PDF: 'pdf/FETCH_PDF',
  DELETE_PDF: 'pdf/DELETE_PDF'
}

const INITIAL_STATE = {
  pdfs: []
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.FETCH_PDF:
      return {
        ...state,
        pdfs: action.payload.pdfs.map((pdf) => pdf)
      }

    case Types.UPLOAD_PDF:
      return {
        ...state,
        pdfs: [...state.pdfs, action.payload]
      }

    case Types.DELETE_PDF:
      return {
        ...state,
        pdfs: state.pdfs.filter((pdf) => pdf.id !== action.payload)
      }

    default:
      return state
  }
}

export const Creators = {
  getAllPdfs: (token) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/pdf/all`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const pdfs = await response.json()

        if (response.ok) {
          dispatch({ type: Types.FETCH_PDF, payload: pdfs })
        } else {
          dispatch(
            messages.setMessage({
              status: 404,
              message: pdfs.message
            })
          )
        }
      } catch (error) {
        dispatch(messages.defaultNetworkError('Loading Pdfs...'))
      }
    }
  },

  addPdf: (newPdf, token) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/pdf/add`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify(newPdf)
          }
        )

        const result = await response.json()
        if (response.ok) {
          newPdf.id = result.pdfId
          dispatch({ type: Types.UPLOAD_PDF, payload: newPdf })
        } else {
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.error.message
            })
          )
        }
        return response.ok
      } catch (error) {
        dispatch(messages.defaultNetworkError())
      }
    }
  },

  deletePdf: (pdfId, token) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/pdf/delete/${pdfId}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )

        const result = await response.json()
        if (response.ok) {
          dispatch({ type: Types.DELETE_PDF, payload: pdfId })
        } else {
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        }
      } catch (error) {
        dispatch(messages.defaultNetworkError())
      }
    }
  }
}
