import decode from 'jwt-decode'

const ACCESS_TOKEN = 'user_session'

const getToken = () => {
  let sessionData = localStorage.getItem(ACCESS_TOKEN)

  if (sessionData) {
    sessionData = JSON.parse(sessionData)

    return sessionData?.token
  }

  return null
}

const getUser = () => {
  let sessionData = localStorage.getItem(ACCESS_TOKEN)

  if (sessionData) {
    sessionData = JSON.parse(sessionData)

    return sessionData?.user
  }

  return null
}

const isLoggedin = () => {
  if (isTokenExpired()) {
    return false
  }
  return true
}

const logout = () => {
  localStorage.clear()
  localStorage.removeItem(ACCESS_TOKEN)
}

const isTokenExpired = (token) => {
  if (!token) {
    token = getToken()
  }
  if (!token) {
    return true
  }

  const date = getTokenExpirationDate(token)
  if (date === undefined) {
    return false
  }
  return !(date.valueOf() > new Date().valueOf())
}

const getTokenExpirationDate = (token) => {
  const decoded = decode(token)

  if (decoded.exp === undefined) {
    return null
  }

  const date = new Date(0)
  date.setUTCSeconds(decoded.exp)
  return date
}

export {
  getToken,
  getUser,
  isLoggedin,
  logout,
  isTokenExpired,
  getTokenExpirationDate
}
