/* eslint-disable */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import PhotoCropper from '../PhotoCropper/PhotoCropper'
import { Badge } from 'primereact/badge'

export default function PhotoFrame({
  newPhoto,
  editable = false,
  manager,
  getCurrentPhoto,
  isActive = false,
  className = '',
  onClick = () => {}
}) {
  const [photo, setPhoto] = useState('')
  const [showCropper, setShowCropper] = useState(false)

  useEffect(() => {
    if (newPhoto && newPhoto.length > 4) {
      setPhoto(newPhoto)
    } else {
      setPhoto('/assets/images/no_picture.png')
    }
  }, [newPhoto])

  const handleOnClick = () => {
    if (editable) {
      setShowCropper(true)
    }
    onClick()
  }

  return (
    <picture
      className={`photo-frame ${className ? className : ''} ${
        editable && 'editable'
      } ${manager && 'manager'}`}
      style={
        editable
          ? { cursor: 'pointer', position: 'relative' }
          : { position: 'relative' }
      }
      onClick={handleOnClick}
    >
      {isActive && (
        <div>
          <Badge
            className="badge-position "
            style={{ backgroundColor: '#25D366', border: '1px solid black' }}
          ></Badge>
        </div>
      )}

      <img src={photo} alt="Bildfehler ..." srcSet="" className="images" />
      {showCropper && (
        <PhotoCropper
          show={showCropper}
          onHide={() => setShowCropper(false)}
          onChange={(e) => {
            getCurrentPhoto(e)
          }}
        />
      )}
    </picture>
  )
}

PhotoFrame.propTypes = {
  newPhoto: PropTypes.string,
  editable: PropTypes.bool,
  manager: PropTypes.bool,
  getCurrentPhoto: PropTypes.func
}
