/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { InputNumber } from 'primereact/inputnumber'
import { Dropdown } from 'primereact/dropdown'
import { useDispatch, useSelector } from 'react-redux'
import { confirmDialog } from 'primereact/confirmdialog' // To use confirmDialog method
import { Calendar } from 'primereact/calendar'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'

import moment from 'moment'

import { Creators as userActions } from '../../redux/ducks/users'
import { Creators as candidatesActions } from '../../redux/ducks/candidates'
import { stages } from '../../utils/stages'

// const stages = [{
//     id: '-1',
//     name: 'Blank'
// }, {
//     id: '0',
//     name: 'Send'
// }, {
//     id: '1',
//     name: 'I'
// }, {
//     id: '2',
//     name: 'II'
// }, {
//     id: '3',
//     name: 'III'
// }, {
//     id: '4',
//     name: 'Deal'
// }, {
//     id: '-2',
//     name: 'Rejected'
// }]

const AddCustomerModal = (props) => {
  const dispatch = useDispatch()

  const { visible, setModal, authToken, candidate, user, jobId } = props
  // const {_id, meetDate, meetTime, stage, owner = {}, details: {} } = candidate;

  const candidates = useSelector((state) => state.candidatesReducer.candidates)
  const candidateIdentites = useSelector(
    (state) => state.candidatesReducer.candidateIdentites
  )

  const [isLoading, setIsLoading] = useState(false)
  const [default20, setDefault20] = useState(true)
  const [candidateForm, setCandidateForm] = useState({
    candidateId: { value: null, valid: true },
    meetDate: { value: null, valid: true },
    meetTime: { value: null, valid: true },
    stage: { value: '-1', valid: true }
  })

  useEffect(() => {
    if (candidate && candidate._id)
      setCandidateForm({
        candidateId: { value: candidate._id, valid: true },
        meetDate: {
          value: candidate.meetDate && moment(candidate.meetDate),
          valid: true
        },
        meetTime: {
          value: candidate.meetTime && moment(candidate.meetTime),
          valid: true
        },
        stage: { value: candidate.stage, valid: true }
      })
  }, [candidate])

  const formInputHandler = (event) => {
    setCandidateForm((prev) => {
      prev[event['name']] = {
        value: event['value'],
        valid: true
      }
      return { ...prev }
    })
  }

  const validateForm = () => {
    let isValid = true
    let form = JSON.parse(JSON.stringify(candidateForm))

    if (!form.candidateId.value) {
      form.candidateId.valid = false
      isValid = false
    }

    if (!form.stage.value) {
      form.stage.valid = false
      isValid = false
    }

    // if (['1', '2', '3'].includes(form.stage.value)) {
    //     if (!form.meetDate.value) {
    //         form.meetDate.valid = false;
    //         isValid = false;
    //     }

    //     if (!form.meetTime.value) {
    //         form.meetTime.valid = false;
    //         isValid = false;
    //     }
    // }

    if (!isValid) {
      setCandidateForm(form)
    }

    return isValid
  }

  const formSubmitHanlder = () => {
    if (validateForm()) {
      setIsLoading(true)
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

      dispatch(
        userActions.addCandidateInsideJob(
          jobId,
          {
            candidateId: candidateForm.candidateId.value,
            stage: candidateForm.stage.value,
            meetDate:
              candidateForm.meetDate.value &&
              moment(candidateForm.meetDate.value).format('YYYY-MM-DD'),
            meetTime:
              candidateForm.meetTime.value &&
              moment(candidateForm.meetTime.value).format('HH:mm'),
            timeZone: timeZone,
            createdBy: user._id
          },
          authToken,
          user
        )
      )
        .then((res) => {
          setIsLoading(false)
          setModal({ visisble: !visible, candidate: {} })
        })
        .catch((err) => {
          setIsLoading(false)
        })
    }
  }

  const promiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      dispatch(
        candidatesActions.getAllCandidatesIdentitiesList(
          authToken,
          1,
          inputValue,
          20,
          false
        )
      )
        .then((res) => {
          setDefault20(false)
          resolve(res)
        })
        .catch((err) => {
          setDefault20(true)
          resolve(candidateIdentites)
        })
    })
  }

  const renderFooter = () => {
    return (
      <div className="footer-btn-container">
        <Button
          label="Abbrechen"
          onClick={() => setModal({ visisble: !visible, candidate: {} })}
          className="p-button-text"
        />
        <button
          className={
            isLoading
              ? 'p-button p-component p-button-loading p-button-loading-label-only p-button-loading-left p-button-cb p-disabled'
              : 'p-button p-component p-button-loading p-button-loading-label-only p-button-loading-left p-button-cb '
          }
          onClick={() => formSubmitHanlder()}
        >
          {isLoading && (
            <span className="p-button-icon p-c p-button-loading-icon pi pi-spinner pi-spin p-button-icon-left "></span>
          )}
          <span className="p-button-label p-c">Speichern</span>
        </button>
      </div>
    )
  }

  return (
    <Dialog
      header={
        candidate && candidate._id ? 'Update Candidates' : 'Add Candidates'
      }
      visible={visible}
      closable={!isLoading}
      position={'center'}
      modal
      style={{ width: '50vw' }}
      footer={renderFooter()}
      onHide={() => setModal(!visible)}
      draggable={false}
      resizable={false}
    >
      <div className="basic-sal-form-container">
        <div className="p-field ">
          <label for="jobTitle">Candidate: </label>
          <AsyncSelect
            placeholder="Search Field"
            classNamePrefix={'rs-dd'}
            className={
              !candidateForm.candidateId.valid
                ? 'p-invalid w-100 rs-dd'
                : 'w-100 rs-dd'
            }
            value={
              default20
                ? candidates.find(
                    (c) => c._id === candidateForm.candidateId.value
                  )
                : candidateIdentites.find(
                    (c) => c._id === candidateForm.candidateId.value
                  )
            }
            onChange={(e) => {
              formInputHandler({
                name: 'candidateId',
                value: e._id
              })
            }}
            defaultOptions={candidates || []}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option._id}
            loadOptions={promiseOptions}
          />
          {/* <Dropdown
                        className={!candidateForm.candidateId.valid ? 'p-invalid w-100' : 'w-100'}
                        value={candidateForm.candidateId.value}
                        options={candidateIdentites || []}
                        // disabled={true}
                        onChange={(e) => {
                            formInputHandler({
                                name: 'candidateId',
                                value: e.value
                            })
                        }}
                        filter
                        optionValue='_id'
                        optionLabel="name"
                        filterBy="name"
                        placeholder="Select" 
                        onFilter={(e) => {
                        }}
                        virtualScrollerOptions={{ 
                            lazy: true,
                            onLazyLoad: onLazyLoad, 
                            itemSize: 38, 
                            showLoader: true, 
                            loading: lazyLoading, 
                            delay: 250, 
                            loadingTemplate: (options) => {
                            return (
                                <div className="flex align-items-center p-2" style={{ height: '38px' }}>
                                    <Skeleton width={options.even ? '60%' : '50%'} height="1rem" />
                                </div>
                            )}
                        }}
                    /> */}
        </div>
        {['1', '2', '3'].includes(candidateForm.stage.value) && (
          <>
            <div className="p-field ">
              <label for="jobTitle">Date: </label>
              <Calendar
                id="time24"
                dateFormat="dd.mm.yy"
                className={
                  !candidateForm.meetDate.valid ? 'p-invalid w-100' : 'w-100'
                }
                value={candidateForm.meetDate.value}
                onChange={(e) =>
                  formInputHandler({
                    name: 'meetDate',
                    value: e.value
                  })
                }
              />
            </div>
            <div className="p-field ">
              <label for="jobTitle">Time: </label>
              <Calendar
                id="time12"
                className={
                  !candidateForm.meetTime.valid ? 'p-invalid w-100' : 'w-100'
                }
                value={candidateForm.meetTime.value}
                onChange={(e) =>
                  formInputHandler({
                    name: 'meetTime',
                    value: e.value
                  })
                }
                timeOnly
                hourFormat="24"
              />
            </div>
          </>
        )}
        <div className="p-field ">
          <label for="jobTitle">Stage: </label>
          <Select
            classNamePrefix={'rs-dd'}
            className={
              !candidateForm.stage.valid
                ? 'p-invalid w-100 rs-dd'
                : 'w-100 rs-dd'
            }
            // value={candidateForm.stage.value}
            value={stages.find((s) => s.id === candidateForm.stage.value)}
            onChange={(e) => {
              formInputHandler({
                name: 'stage',
                value: e.id
              })
            }}
            options={stages.filter((item) => item.id != '-2') || []}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
          />
          {/* <Dropdown
                        className={!candidateForm.stage.valid ? 'p-invalid w-100' : 'w-100'}
                        value={candidateForm.stage.value}
                        options={stages}
                        onChange={(e) => {
                            formInputHandler({
                                name: 'stage',
                                value: e.value
                            })
                        }}
                        // filter
                        optionValue='id'
                        optionLabel="name"
                        filterBy="name"
                        placeholder="Select" /> */}
        </div>
      </div>
    </Dialog>
  )
}

export default AddCustomerModal

{
  /* <InputNumber
                        className={!salaryForm.salary.valid ? 'p-invalid p-mt-1' : 'p-mt-1'}
                        value={salaryForm.salary.value}
                        id="salary"
                        name="salary"
                        minFractionDigits={0}
                        mode="currency"
                        currencyDisplay='symbol'
                        currency={salaryForm.currency.value}
                        locale={"de-DE"}
                        onChange={(event) => {
                            formInputHandler({
                                'name': 'salary',
                                value: event.value,
                            })
                        }}
                        placeholder={'Grundgehalt'}
                    /> */
}
