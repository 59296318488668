// Action types
const Types = {
  OPEN_PANEL: 'addDataPanel/OPEN_PANEL',
  CLOSE_PANEL: 'addDataPanel/CLOSE_PANEL'
}

// Reducer
const INITIAL_STATE = {
  collapsed: true,
  // Path stores the component url from where this actions are called.
  path: null
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.OPEN_PANEL:
      return { ...state, collapsed: false, path: action.payload }

    case Types.CLOSE_PANEL:
      return { ...state, collapsed: true, path: null }

    default:
      return state
  }
}

// Actions
export const Creators = {
  openPanel: (path) => {
    return (dispatch) => {
      setTimeout(() => {
        dispatch({ type: Types.OPEN_PANEL, payload: path })
      }, 300)
    }
  },
  closePanel: () => {
    return (dispatch) => {
      dispatch({ type: Types.CLOSE_PANEL })
    }
  }
}
