export const Types = {
  SET_SEARCH_EXPRESSION: 'search/SET_SEARCH_EXPRESSION',
  RESET_SEARCH_TERMS: 'search/RESET_SEARCH_TERMS'
}

// Reducer
const INITIAL_STATE = {
  searchExpression: []
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_SEARCH_EXPRESSION:
      return {
        searchExpression: action.payload
      }
    case Types.RESET_SEARCH_TERMS:
      return {
        searchExpression: []
      }
    default:
      return state
  }
}
// Actions
export const Creators = {
  setExpression: (expression) => {
    return async (dispatch) => {
      dispatch({
        type: Types.SET_SEARCH_EXPRESSION,
        payload: expression
      })
    }
  },
  resetSearch: (reset) => {
    return async (dispatch) => {
      dispatch({
        type: Types.RESET_SEARCH_TERMS,
        payload: reset
      })
    }
  }
}
