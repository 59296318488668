/* eslint-disable */
import axios from '../../intercepters/axios'
import { Creators as messages } from './statusMessages'
import { Creators as auth } from './auth'

export const Types = {
  RADAR_GET_LIST: 'candidateRadar/RADAR_GET_LIST',
  RADAR_CREATE_CANDIDATE: 'candidateRadar/RADAR_CREATE_CANDIDATE',
  RADAR_DELETE_CANDIDATE: 'candidateRadar/RADAR_DELETE_LIST'
}

// Reducer
const INITIAL_STATE = {
  candidatesList: {
    list: [],
    totalCount: 0,
    page: 0
  }
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RADAR_GET_LIST: {
      const { count, records } = action.payload
      const newList = JSON.parse(JSON.stringify(state.candidatesList.list))
      return {
        ...state,
        candidatesList: {
          ...state.candidatesList,
          list: [...records],
          totalCount: count
        }
      }
    }
    case Types.RADAR_CREATE_CANDIDATE: {
      const { result, user } = action.payload
      const newList = JSON.parse(JSON.stringify(state.candidatesList.list))
      const findCandidate = newList.find(
        (ca) => ca._id === result.candidate._id
      )
      if (findCandidate) {
        const cIdx = newList.indexOf(findCandidate)
        if (cIdx > -1) {
          newList[cIdx] = {
            candidate: result.candidate,
            owner: { ...user },
            _id: result._id
          }
          return {
            ...state,
            candidatesList: {
              ...state.candidatesList,
              list: [...newList]
            }
          }
        }
      }
      newList.push({
        candidate: result.candidate,
        owner: { ...user },
        _id: result._id
      })
      return {
        ...state,
        candidatesList: {
          ...state.candidatesList,
          list: [...newList]
        }
      }
    }
    case Types.RADAR_DELETE_CANDIDATE: {
      const { id } = action.payload
      const newList = JSON.parse(JSON.stringify(state.candidatesList.list))
      const findCandidate = newList.find((ca) => ca._id === id)

      if (findCandidate) {
        const cIdx = newList.indexOf(findCandidate)
        if (cIdx > -1) {
          newList.splice(cIdx, 1)
          return {
            ...state,
            candidatesList: {
              ...state.candidatesList,
              list: [...newList]
            }
          }
        }
      }
    }
    default:
      return state
  }
}

export const Creators = {
  addCandidate: (candidate, user, token) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/radar`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify({
              candidateId: candidate,
              createdBy: user._id
            })
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.RADAR_CREATE_CANDIDATE,
            payload: {
              result: result.data,
              user: user
            }
          })
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else if (response.status === 401) {
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else {
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        }
        return result
      } catch (error) {
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  getCandidatesList: (limit, token) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/radar`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.RADAR_GET_LIST,
            payload: result.data
          })
        } else if (response.status === 401) {
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        }
      } catch (error) {
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  deleteCandidate: (id, token) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/radar/${id}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.RADAR_DELETE_CANDIDATE,
            payload: { id }
          })

          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else if (response.status === 401) {
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else {
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        }
        return response.ok
      } catch (error) {
        dispatch(messages.defaultNetworkError())
      }
    }
  }
}
