import { Creators as messages } from './statusMessages'

// Action types
const Types = {
  LOAD_COMPANYOPTION: 'companieOption/LOAD_COMPANYOPTION',
  ADD_COMPANYOPTION: 'companieOption/ADD_COMPANYOPTION',
  UPDATE_COMPANYOPTION: 'companieOption/UPDATE_COMPANYOPTION',
  DELETE_COMPANYOPTION: 'companieOption/DELETE_COMPANYOPTION'
}

const INITIAL_STATE = {
  companieOptions: []
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LOAD_COMPANYOPTION:
      return {
        ...state,
        companieOptions: action.payload.companieOptions.map(
          (companieOption) => companieOption
        )
      }

    case Types.ADD_COMPANYOPTION:
      return {
        ...state,
        companieOptions: [...state.companieOptions, action.payload]
      }

    case Types.UPDATE_COMPANYOPTION:
      return {
        ...state,
        companieOptions: state.companieOptions.map((companieOption) =>
          companieOption.id === action.payload.id
            ? { ...companieOption, ...action.payload }
            : companieOption
        )
      }

    case Types.DELETE_COMPANYOPTION:
      return {
        ...state,
        companieOptions: state.companieOptions.filter(
          (companieOption) => companieOption.id !== action.payload
        )
      }

    default:
      return state
  }
}

export const Creators = {
  getAllCompanieOption: (token) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/companieOption/all`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const companieOptions = await response.json()
        if (response.ok) {
          dispatch({ type: Types.LOAD_COMPANYOPTION, payload: companieOptions })
        } else {
          dispatch(
            messages.setMessage({
              status: 404,
              message: companieOptions.message
            })
          )
        }
      } catch (error) {
        dispatch(messages.defaultNetworkError('Loading companie Option...'))
      }
    }
  },

  addCompanieOption: (newCompanieOption, token) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/companieOption`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify(newCompanieOption)
          }
        )

        const result = await response.json()
        if (response.ok) {
          newCompanieOption.id = result.companieOptionsId
          dispatch({
            type: Types.ADD_COMPANYOPTION,
            payload: newCompanieOption
          })
        } else {
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.error.message
            })
          )
        }
        return response.ok
      } catch (error) {
        dispatch(messages.defaultNetworkError())
      }
    }
  },

  updateCompanieOption: (prevCompanieOption, editedCompanieOption, token) => {
    return async (dispatch) => {
      if (!editedCompanieOption) {
        dispatch({
          type: Types.UPDATE_COMPANYOPTION,
          payload: prevCompanieOption
        })
        return null
      }
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/companieOption`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify(editedCompanieOption)
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.UPDATE_COMPANYOPTION,
            payload: editedCompanieOption
          })
        } else {
          dispatch({
            type: Types.UPDATE_COMPANYOPTION,
            payload: prevCompanieOption
          })
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        }
      } catch (error) {
        dispatch(messages.defaultNetworkError())
      }
    }
  },

  deleteAllCompanieOption: (companieOptionId, token) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/companieOption/${companieOptionId}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )

        const result = await response.json()
        if (response.ok) {
          dispatch({ type: DELETE_COMPANYOPTION, payload: companieOptionId })
        } else {
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        }
      } catch (error) {
        dispatch(messages.defaultNetworkError())
      }
    }
  }
}
