/* eslint-disable */
import fetchIntercept from 'fetch-intercept'
import { getToken, logout } from '../helpers/jwt'

const unRegister = fetchIntercept.register({
  request: function (url, config) {
    const token = getToken()
    if (token) {
      config['headers']['Authorization'] = token
    }

    // Modify the url or config here
    return [url, config]
  },

  requestError: function (error) {
    // Called when an error occured during another 'request' interceptor call
    return Promise.reject(error)
  },

  response: function (response) {
    if (response.status === 401) {
      logout()
      response.status = 500
    }
    // Modify the reponse object
    return response
  },

  responseError: function (error) {
    // Handle an fetch error
    return Promise.reject(error)
  }
})

export { unRegister }
