import React from 'react'
import { BsBuilding } from 'react-icons/bs'
import { RiCompass3Line } from 'react-icons/ri'

// Action types
export const Types = {
  DASHBOARD: 'tabmenu/DASHBOARD',
  UNTERNEHMEN_TAB: 'tabmenu/UNTERNEHMEN_TAB',
  KANDIDATEN_TAB: 'tabmenu/KANDIDATEN_TAB',
  UNKNOWN_TAB: 'tabmenu/UNKNOWN_TAB'
}

// Reducer
const INITIAL_STATE = {
  model: [
    {
      label: 'Dashboard',
      icon: 'pi pi-fw pi-briefcase',
      path: '/',
      className: 'borderless',
      template: (item, options) => {
        return (
          <a
            onClick={(e) => options.onClick(e)}
            className="p-menuitem-link"
            role="presentation"
          >
            <RiCompass3Line className="p-menuitem-icon" fontSize={'0.9rem'} />
            <span className="p-menuitem-text">Dashboard</span>
          </a>
        )
      }
    },
    {
      label: 'Unternehmen',
      icon: 'pi pi-fw pi-briefcase',
      path: '/unternehmen',
      template: (item, options) => {
        return (
          <a
            onClick={(e) => options.onClick(e)}
            className="p-menuitem-link"
            role="presentation"
          >
            <BsBuilding className="p-menuitem-icon" fontSize={'0.9rem'} />
            <span className="p-menuitem-text">Unternehmen</span>
          </a>
        )
      }
    },
    // {
    //   label: '',
    //   icon: 'pi pi-plus',
    //   className: 'plusIcon',
    //   path: '/unternehmen'
    // },
    {
      label: 'Kandidaten',
      icon: 'pi pi-fw pi-users',
      path: '/kandidaten'
    }
    // {
    //   label: '',
    //   icon: 'pi pi-plus',
    //   className: 'plusIcon',
    //   path: '/kandidaten'
    // }
  ],
  activeTab: {}
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.DASHBOARD:
      return { ...state, activeTab: state.model[0] }
    case Types.UNTERNEHMEN_TAB:
      return { ...state, activeTab: state.model[1] }
    case Types.KANDIDATEN_TAB:
      return { ...state, activeTab: state.model[2] }
    case Types.UNKNOWN_TAB:
      return { ...state, activeTab: {} }
    default:
      return state
  }
}

// Actions
export const Creators = {
  setSelectedTab: (url) => {
    const validPaths = {
      '/': Types.DASHBOARD,
      '/unternehmen': Types.UNTERNEHMEN_TAB,
      '/kandidaten': Types.KANDIDATEN_TAB
    }
    return (dispatch) => {
      if (validPaths[url]) {
        dispatch({ type: validPaths[url] })
      } else {
        dispatch({ type: Types.UNKNOWN_TAB })
      }
    }
  }
}
