/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { MegaMenu } from 'primereact/megamenu'
import { Creators as authActions } from '../../redux/ducks/auth'
import { Creators as userActions } from '../../redux/ducks/users'
import { Creators as addDataPanelActions } from '../../redux/ducks/addDataPanel'
import { BsPauseCircle } from 'react-icons/bs'
import Countdown from 'react-countdown'
import * as moment from 'moment'
import { IconButton, MenuItem, Menu } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { Badge } from 'primereact/badge'
import { BsFillBellFill, BsBuilding, BsCheck2 } from 'react-icons/bs'
import { CgMenuRound } from 'react-icons/cg'
import NotificationSound from './notification-sound.mp3'
function MenuBar({
  authState,
  authLogout,
  userState,
  userActions,
  hideBell,
  notificationIsRead,
  getNotifications,
  getCurrentTimeLog
}) {
  const isAdmin = authState.user.admin
  const history = useHistory()
  const audioPlayer = useRef(null)

  let [currentTimeState, setCurrentTimeState] = useState('current')
  let [countDownTimer, setCountDownTimer] = useState({
    milliseconds: 0,
    timeString: ''
  })
  let [blurText, setBlurText] = useState(false)
  const [notificationDropdown, setNotificationDropdown] = useState(null)

  useEffect(() => {
    getNotifications(authState.token)

    // let intervalId = setInterval(() => {
    //   getCurrentTimeLog(authState.token)
    // }, 60000)
    // return (() => {
    //   clearInterval(intervalId)
    // })
  }, [])

  const getCountTimer = () => {}

  useEffect(() => {
    if (authState.notificationId) {
      getNotifications(authState.token)
    }
  }, [authState.notificationId])
  useEffect(() => {
    if (userState && userState.userTimeLog && userState.userTimeLog.loginTime) {
      let maxTime = 10 * 60 * 60 * 1000
      let diff = maxTime - userState.userTimeLog.loginTime
      let time = moment().startOf('day').add(diff, 'milliseconds')
      let minutes = 10 - (time.minute() % 10)
      time = time.add(minutes, 'minutes').format('hh:mm')
      setCountDownTimer({
        milliseconds: diff,
        timeString: time
      })
    }
  }, [userState.userTimeLog])
  let items = [
    {},
    {
      template: (item, options) => {
        return (
          <>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={true ? 'long-menu' : undefined}
              aria-expanded={true ? 'true' : undefined}
              aria-haspopup="true"
              className="notification-button"
              onClick={(e) => {
                getNotifications(authState.token)
                setNotificationDropdown(e.currentTarget)
                hideBell()
              }}
            >
              <div className="notification-icon">
                {authState.userNotification &&
                  authState.userNotification.length > 0 && (
                    <Badge
                      className={
                        authState.userNotification.length >= 9 &&
                        'p-badge-double'
                      }
                      value={authState.userNotification.length}
                      severity="warning"
                    ></Badge>
                  )}

                <BsFillBellFill className="p-overlay-badge"></BsFillBellFill>
              </div>
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button'
              }}
              className="notification-dropdown"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              // anchorPosition={{top: 65, left: 2}}
              anchorEl={notificationDropdown}
              open={Boolean(notificationDropdown)}
              onClose={() => setNotificationDropdown(null)}
              // PaperProps={{
              //   style: {
              //     maxHeight: 48,
              //     width: '20ch',
              //   },
              // }}
            >
              {authState.userNotification &&
                authState.userNotification.map((option, index) => (
                  <MenuItem
                    key={`$menuitem-${index}-${option._id}`}
                    className="notification-item remove-hovering-effect"
                  >
                    <div className="item-header">
                      {option.notification_entity === 'Company' && (
                        <BsBuilding onClick={() => {}} className="left-icon" />
                      )}
                      {option.notification_entity === 'Candidate' && (
                        <span
                          className="pi pi-fw pi-users"
                          style={{ marginRight: '16px' }}
                          onClick={() => {}}
                        />
                      )}
                      {/* <BsBuilding onClick={()=>{
                    if(option.notification_entity==='Company')
                    {
                      history.push({
                        pathname:'/unternehmen',
                        search:`?searchParams=${option.from}`
                      })
                    }
                    else if(option.notification_entity==='Candidate')
                    {
                      history.push({
                        pathname:'/kandidaten',
                        search:`?searchParams=${option.from}`
                      })
                    }
                  }} className='left-icon' /> */}
                      <span
                        className="head-title "
                        onClick={() => {
                          let path =
                            option.notification_entity === 'Company'
                              ? '/unternehmen'
                              : '/kandidaten'
                          history.push({
                            pathname: path,
                            search: `?searchParams=${option.from}`
                          })
                        }}
                        style={{ textTransform: 'capitalize' }}
                      >
                        {option.from}
                      </span>
                      <BsCheck2
                        className="right-icon"
                        onClick={() =>
                          notificationIsRead(authState.token, option._id)
                        }
                      />
                    </div>
                    <div
                      className="item-body"
                      dangerouslySetInnerHTML={{ __html: option.msg }}
                    ></div>
                  </MenuItem>
                ))}
            </Menu>
          </>
        )
      }
    },
    {
      // label: authState.user.name,
      template: (item, options) => {
        return <CgMenuRound className="menuicon" />
      },
      command: () => {
        const isMitarbeiterVisible = items[2].items[0][0].items[3]
        if (isAdmin === false) {
          isMitarbeiterVisible.className = 'show_no_mit'
        }
      },
      // expanded: true,
      items: [
        [
          {
            items: [
              // {
              //   label:  authState.user.name,
              //   className: 'categories',
              //   icon: 'pi pi-user',
              //   command: () => {
              //     history.push('/')
              //   }
              // },
              {
                label: 'Profit Center',
                className: 'categories',
                icon: 'pi pi-euro',
                command: () => {
                  history.push('/profitcenter')
                }
              },
              {
                label: 'Academy',
                className: 'categories',
                icon: 'pi pi-bookmark',
                command: () => {
                  window.open('https://vimeo.com/showcase/9100460', '_blank')
                }
              },
              {
                label: 'Schlagworte',
                className: 'category-schlagworte categories',
                icon: 'pi pi-fw pi-tags',
                command: () => {
                  history.push('/admin/tags')
                }
              },
              {
                label: 'Mitarbeiter',
                className: 'categories',
                icon: 'pi pi-fw pi-users',
                command: () => {
                  history.push('/admin/mitarbeiten')
                }
              },
              {
                label: 'Logout',
                className: 'category-logout categories',
                icon: 'pi pi-fw pi-sign-out',
                command: () => {
                  authLogout(authState.token)
                }
              }
            ]
          }
        ]
      ],
      className: 'noti-pright'
    }
  ]
  if (authState && authState.user.isClockEnabled) {
    items[0] = {
      template: (item, options) => {
        if (countDownTimer.milliseconds < 0) {
          authLogout(authState.token)
        } else
          return (
            <span
              className={blurText && 'blurry-text'}
              onClick={() => {
                console.log('')
              }}
            >
              {countDownTimer.timeString}
            </span>
          )
      },
      icon: 'pi pi-clock',
      className: 'clock-icon'
    }
  }
  return (
    <MegaMenu
      id="topbar-menubar"
      model={items}
      orientation="horizontal"
    ></MegaMenu>
  )
}

MenuBar.propTypes = {
  authState: PropTypes.object.isRequired,
  authLogout: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  authState: state.authReducer,
  userState: state.usersReducer
})

const mapDispatchToProps = (dispatch) => ({
  authLogout: (token) => dispatch(authActions.logout(token)),
  getNotifications: (token) => dispatch(authActions.createNotification(token)),
  hideBell: () => dispatch(authActions.notificationGetEmit('hide_bell')),
  notificationIsRead: (token, id) =>
    dispatch(authActions.notificationIsRead(token, id)),
  addDataPanelOpen: (path) => dispatch(addDataPanelActions.openPanel(path)),
  getCurrentTimeLog: (token) => dispatch(userActions.getCurrentTimeLog(token))
})

export default connect(mapStateToProps, mapDispatchToProps)(MenuBar)
