/* eslint-disable */
import { Creators as messages } from './statusMessages'
import { Creators as auth } from './auth'
import _ from 'lodash'
// Action types
export const Types = {
  USER_CLOCK_REQUEST: 'users/USER_CLOCK_REQUEST',
  USER_CLOCK_SUCCESS: 'users/USER_CLOCK_SUCCESS',
  USER_CLOCK_FAIL: 'users/USER_CLOCK_FAIL',
  USER_REGISTER_REQUEST: 'users/USER_REGISTER_REQUEST',
  USER_REGISTER_SUCCESS: 'users/USER_REGISTER_SUCCESS',
  USER_REGISTER_FAIL: 'users/USER_REGISTER_FAIL',
  USER_UPDATE_REQUEST: 'users/USER_UPDATE_REQUEST',
  USER_UPDATE_SUCCESS: 'users/USER_UPDATE_SUCCESS',
  USER_UPDATE_FAIL: 'users/USER_UPDATE_FAIL',
  USER_RESET_KEY_REQUEST: 'users/USER_RESET_KEY_REQUEST',
  USER_RESET_KEY_SUCCESS: 'users/USER_RESET_KEY_SUCCESS',
  USER_RESET_KEY_FAIL: 'users/USER_RESET_KEY_FAIL',
  USER_DELETE_REQUEST: 'users/DELETE_REQUEST',
  USER_DELETE_SUCCESS: 'users/DELETE_SUCCESS',
  USER_DELETE_FAIL: 'users/DELETE_FAIL',
  USERS_LOADING_REQUEST: 'users/USER_LOADING_REQUEST',
  USERS_LOADING_SUCCESS: 'users/USER_LOADING_SUCCESS',
  USERS_LOADING_FAIL: 'users/USER_LOADING_FAIL',
  USERS_TIME_LOG_REQUEST: 'users/USERS_TIME_LOG_REQUEST',
  USERS_TIME_LOG_SUCCESS: 'users/USERS_TIME_LOG_SUCCESS',
  USERS_TIME_LOG_FAIL: 'users/USERS_TIME_LOG_FAIL',
  USERS_CURRENT_TIME_LOG_REQUEST: 'users/USERS_CURRENT_TIME_LOG_REQUEST',
  USERS_CURRENT_TIME_LOG_SUCCESS: 'users/USERS_CURRENT_TIME_LOG_SUCCESS',
  USERS_CURRENT_TIME_LOG_FAIL: 'users/USERS_CURRENT_TIME_LOG_FAIL',
  USERS_LOADING_SEARCH_SUCCESS: 'users/USERS_LOADING_SEARCH_SUCCESS',
  USERS_ALL_LIST_LOADING_REQUEST: 'users/USERS_ALL_LIST_LOADING_REQUEST',
  USERS_ALL_LIST_LOADING_SUCCESS: 'users/USERS_ALL_LIST_LOADING_SUCCESS',
  USERS_ALL_LIST_LOADING_FAIL: 'users/USERS_ALL_LIST_LOADING_FAIL',
  USER_GET_SALARIES: 'users/USER_GET_SALARIES',
  USER_ADD_SALARIES: 'users/USER_ADD_SALARIES',
  USER_DELETE_SALARIES: 'users/USER_DELETE_SALARIES',
  USER_EDIT_SALARIES: 'users/USER_EDIT_SALARIES',
  USERS_ALL_LIST_IDENTITIES: 'users/USERS_ALL_LIST_IDENTITIES',
  USER_GET_JOBS: 'users/USER_GET_JOBS',
  CANDIDATE_ADD_JOB: 'users/CANDIDATE_ADD_JOB',
  CANDIDATE_UPDATE_JOB: 'users/CANDIDATE_UPDATE_JOB',
  CANDIDATE_DELETE_JOB: 'users/CANDIDATE_DELETE_JOB',
  ADMIN_FILTER_USER: 'users/ADMIN_FILTER_USER',
  PROTOCOL_COUNT: 'users/PROTOCOL_COUNT'
}

// Reducer
const INITIAL_STATE = {
  isLoading: false,
  adminUserFilter: null,
  users: [],
  userIdentites: [],
  userAllIdentities: [],
  timeLogs: [],
  usersSearch: {},
  updatedUser: null,
  userTimeLog: {},
  usersList: {
    list: [],
    totalCount: 0
  },
  userJobsList: {
    list: [],
    totalCount: 0
  },
  userUpdated: {},
  userProtocols: {
    max: 30,
    total: 0,
    users: [],
    usersPageCount: 0
  }
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.USER_REGISTER_REQUEST:
    case Types.USERS_LOADING_REQUEST:
    case Types.USER_UPDATE_REQUEST:
    case Types.USER_DELETE_REQUEST:
    case Types.ADMIN_FILTER_USER:
      return {
        ...state,
        adminUserFilter:
          (action && action.payload && action.payload.userId) || null
      }
    case Types.USER_RESET_KEY_REQUEST:
      return { ...state, isLoading: true }
    case Types.USERS_TIME_LOG_REQUEST:
      return {
        ...state,
        timeLogs: []
      }
    case Types.USER_REGISTER_SUCCESS:
      return {
        ...state,
        // users: [{ ...action.payload.data }, ...state.users],
        userUpdated: action.payload,
        isLoading: false
      }
    case Types.USER_CLOCK_SUCCESS:
      return {
        ...state,
        // users: [{ ...action.payload.data }, ...state.users],
        userUpdated: { id: action.payload.id },
        isLoading: false
      }

    case Types.USER_REGISTER_FAIL:
      return { ...state, isLoading: false }

    case Types.USER_UPDATE_SUCCESS: {
      const list = _.cloneDeep(state.usersList.list)
      const usersList = _.cloneDeep(state.users)
      const userIndex = list.indexOf(
        list.find((item) => item._id === action.payload.id)
      )
      const usersIndex = usersList.indexOf(
        usersList.find((item) => item._id === action.payload.id)
      )

      if (userIndex > -1) {
        list[userIndex] = { ...list[userIndex], ...action.payload.user }
      }

      if (usersIndex > -1) {
        usersList[usersIndex] = {
          ...usersList[usersIndex],
          ...action.payload.user
        }
      }

      if (userIndex > -1 || usersIndex > -1) {
        return {
          ...state,
          userUpdated: action.payload,
          isLoading: false,
          usersList: {
            ...state.usersList,
            list: [...list]
          },
          users: [...usersList]
        }
      }
      // return {
      //   ...state,
      //   userUpdated: action.payload,
      //   isLoading: false,
      //   usersList: {
      //     ...state.usersList,
      //     list: [...list]
      //   }
      // }
      return {
        ...state,
        userUpdated: action.payload,
        isLoading: false
      }
    }
    case Types.USER_ADD_SALARIES: {
      const list = _.cloneDeep(state.usersList.list)
      const userIndex = list.indexOf(
        list.find((item) => item._id === action.payload.id)
      )
      if (userIndex > -1) {
        const findSalaIndex = list[userIndex]['salaries'].indexOf(
          list[userIndex]['salaries'].find(
            (item) => item._id === action.payload.result._id
          )
        )
        if (findSalaIndex > -1) {
          list[userIndex]['salaries'][findSalaIndex] = action.payload.result
        } else {
          list[userIndex]['salaries'].push(action.payload.result)
        }
        list[userIndex]['salaries'] = _.sortBy(
          list[userIndex]['salaries'],
          ['year', 'month'],
          ['desc', 'desc']
        ).reverse()
        return {
          ...state,
          // userUpdated: action.payload,
          isLoading: false,
          usersList: {
            ...state.usersList,
            list: [...list]
          }
        }
      }
    }
    case Types.USER_DELETE_SALARIES: {
      const list = _.cloneDeep(state.usersList.list)
      const userIndex = list.indexOf(
        list.find((item) => item._id === action.payload.userId)
      )
      if (userIndex > -1) {
        const findSalaIndex = list[userIndex]['salaries'].indexOf(
          list[userIndex]['salaries'].find(
            (item) => item._id === action.payload.salId
          )
        )
        if (findSalaIndex > -1) {
          const findBillIndex = list[userIndex]['salaries'][findSalaIndex][
            'bills'
          ].indexOf(
            list[userIndex]['salaries'][findSalaIndex]['bills'].find(
              (item) => item._id === action.payload.billId
            )
          )
          if (findBillIndex > -1) {
            list[userIndex]['salaries'][findSalaIndex]['bills'].splice(
              findBillIndex,
              1
            )
          }
        }
        return {
          ...state,
          // userUpdated: action.payload,
          isLoading: false,
          usersList: {
            ...state.usersList,
            list: [...list]
          }
        }
      }
      return state
    }
    case Types.PROTOCOL_COUNT: {
      const { count, users, usersCount } = action.payload.data
      return {
        ...state,
        userProtocols: {
          max: 30,
          total: count,
          users: [...users],
          usersPageCount: usersCount
        }
      }
    }
    case Types.USER_EDIT_SALARIES: {
      const list = _.cloneDeep(state.usersList.list)
      const userIndex = list.indexOf(
        list.find((item) => item._id === action.payload.id)
      )
      if (userIndex > -1) {
        const salaryToUpdate = action.payload.result
        const findSalaIndex = list[userIndex]['salaries'].indexOf(
          list[userIndex]['salaries'].find(
            (item) => item._id === salaryToUpdate._id
          )
        )

        if (findSalaIndex > -1) {
          list[userIndex]['salaries'][findSalaIndex] = salaryToUpdate
          return {
            ...state,
            // userUpdated: action.payload,
            isLoading: false,
            usersList: {
              ...state.usersList,
              list: [...list]
            }
          }
        }
      }
    }
    case Types.USER_GET_SALARIES: {
      const { result, id, page } = action.payload
      const list = JSON.parse(JSON.stringify(state.usersList.list))
      const userIndex = list.indexOf(list.find((item) => item._id === id))
      if (userIndex > -1) {
        list[userIndex]['salaries'] = result['data']['records']
        list[userIndex]['salariesCount'] = result['data']['count']
        list[userIndex]['salaryPage'] = page
        return {
          ...state,
          isLoading: false,
          usersList: {
            ...state.usersList,
            list: [...list]
          }
        }
      }
    }
    case Types.USER_DELETE_SUCCESS:
      return {
        ...state,
        users: state.users.filter((user) => user._id !== action.payload.id),
        isLoading: false
      }
    case Types.USERS_ALL_LIST_LOADING_SUCCESS:
      return {
        ...state,
        usersList: {
          list: [...action.payload.data],
          totalCount: action.payload.totalCount
        },
        isLoading: false
      }
    case Types.USERS_ALL_LIST_IDENTITIES: {
      return {
        ...state,
        userIdentites: [...action.payload.data.filter((item) => item.active)],
        userAllIdentities: [...action.payload.data]
      }
    }
    case Types.USER_GET_JOBS: {
      const { result } = action.payload
      return {
        ...state,
        userJobsList: {
          ...state.userJobsList,
          list: result['data']['records'],
          totalCount: result['data']['count']
        }
      }
    }
    case Types.CANDIDATE_ADD_JOB: {
      const { result, id, user } = action.payload
      const list = _.cloneDeep(state.userJobsList.list)
      const jobIndex = list.indexOf(list.find((item) => item._id === id))
      if (jobIndex > -1) {
        list[jobIndex].candidates.push({ ...result, owner: user })
        list[jobIndex].candidates = _.orderBy(
          list[jobIndex].candidates,
          ['stage'],
          ['desc']
        )
        return {
          ...state,
          isLoading: false,
          userJobsList: {
            ...state.userJobsList,
            list: _.cloneDeep(list)
          }
        }
      }
    }
    case Types.CANDIDATE_UPDATE_JOB: {
      const { result, id, user } = action.payload
      const list = _.cloneDeep(state.userJobsList.list)
      const jobIndex = list.indexOf(list.find((item) => item._id === id))
      const candidateIndex = list[jobIndex].candidates.indexOf(
        list[jobIndex].candidates.find(
          (candidate) => candidate._id === result._id
        )
      )
      if (jobIndex > -1 && candidateIndex > -1) {
        if (result.stage == '-2') {
          list[jobIndex].candidates.splice(candidateIndex, 1)
        } else {
          list[jobIndex].candidates[candidateIndex] = {
            ...list[jobIndex].candidates[candidateIndex],
            ...result
          }
          list[jobIndex].candidates = _.orderBy(
            list[jobIndex].candidates,
            ['stage'],
            ['desc']
          )
        }
        return {
          ...state,
          isLoading: false,
          userJobsList: {
            ...state.userJobsList,
            list: _.cloneDeep(list)
          }
        }
      }
    }
    case Types.CANDIDATE_DELETE_JOB: {
      const { result, id, user } = action.payload
      const list = _.cloneDeep(state.userJobsList.list)
      const jobIndex = list.indexOf(
        list.find((item) => item._id === id && item.owner._id !== result.user)
      )
      if (jobIndex > -1) {
        list.splice(jobIndex, 1)
        return {
          ...state,
          isLoading: false,
          userJobsList: {
            ...state.userJobsList,
            list: _.cloneDeep(list)
          }
        }
      }
    }
    case Types.USER_UPDATE_FAIL:
    case Types.USER_DELETE_FAIL:
    case Types.USER_RESET_KEY_FAIL:
    case Types.USER_RESET_KEY_SUCCESS:
      return { ...state, isLoading: false }

    case Types.USERS_LOADING_SUCCESS:
      return {
        ...state,
        users: Object.assign([], action.payload.users),
        isLoading: false
      }
    case Types.USERS_TIME_LOG_SUCCESS:
      return {
        ...state,
        timeLogs: [...action.payload.data],
        isLoading: false
      }
    case Types.USERS_CURRENT_TIME_LOG_SUCCESS:
      return {
        ...state,
        userTimeLog:
          action.payload.data &&
          action.payload.data.log &&
          action.payload.data.log.length > 0
            ? action.payload.data.log[0]
            : {},
        isLoading: false
      }
    case Types.USERS_LOADING_SEARCH_SUCCESS:
      return {
        ...state,
        usersSearch: action.payload,
        isLoading: false
      }
    case Types.USERS_LOADING_FAIL:
      return { ...state, users: [], isLoading: false }

    default:
      return state
  }
}

// Actions
export const Creators = {
  changeClockTime: (id, token) => {
    return async (dispatch) => {
      dispatch({ type: Types.USER_CLOCK_REQUEST })

      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/user/changeClock/${id}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.USER_CLOCK_SUCCESS,
            payload: { id }
          })
        } else {
          dispatch({ type: Types.USER_CLOCK_FAIL })
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.error.message
            })
          )
        }
        return response.ok
      } catch (error) {
        dispatch({ type: Types.USER_CLOCK_FAIL })
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  addUser: (newUser, token) => {
    return async (dispatch) => {
      dispatch({ type: Types.USER_REGISTER_REQUEST })

      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/user/`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify(newUser)
          }
        )
        const result = await response.json()
        if (response.ok) {
          if (newUser.photo) {
            const photo = newUser.photo.data
            newUser.photo = photo
          }
          dispatch({
            type: Types.USER_REGISTER_SUCCESS,
            payload: result.fixedDocuments
              ? { ...newUser, fixedDocuments: result.fixedDocuments }
              : newUser
          })
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else if (response.status === 401) {
          dispatch({ type: Types.USERS_LOADING_FAIL })
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else {
          dispatch({ type: Types.USER_REGISTER_FAIL })
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.error.message
            })
          )
        }
        return response.ok
      } catch (error) {
        dispatch({ type: Types.USER_REGISTER_FAIL })
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  getAllUsersList: (
    token,
    page = 1,
    search = '',
    limit = 20,
    active = false
  ) => {
    return async (dispatch) => {
      dispatch({
        type: Types.USERS_ALL_LIST_LOADING_REQUEST
      })
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/user/getUsersList/`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify({
              search,
              page,
              limit,
              active
            })
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.USERS_ALL_LIST_LOADING_SUCCESS,
            payload: result.result
          })
        } else if (response.status === 401) {
          dispatch({
            type: Types.USERS_ALL_LIST_LOADING_FAIL
          })
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else {
          dispatch({
            type: Types.COMPANY_LIST_LOADING_FAIL
          })
        }

        return response.ok
      } catch (error) {
        console.log('This is userlist error')
        dispatch({
          type: Types.COMPANY_LIST_LOADING_FAIL
        })
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  getAllUsers: (token) => {
    return async (dispatch) => {
      // dispatch({ type: Types.USERS_LOADING_REQUEST })

      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/user/all`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const data = await response.json()
        if (response.ok) {
          dispatch({ type: Types.USERS_LOADING_SUCCESS, payload: data })
        } else if (response.status === 401) {
          dispatch({ type: Types.USERS_LOADING_FAIL })
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: data.message
            })
          )
        }
      } catch (error) {
        dispatch({ type: Types.USERS_LOADING_FAIL })
        dispatch(messages.defaultNetworkError('Loading users...'))
      }
    }
  },
  getTimeLogs: (token, userId, time = '') => {
    return async (dispatch) => {
      dispatch({ type: Types.USERS_TIME_LOG_REQUEST })
      let timeZone = new Date().getTimezoneOffset()

      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/user/timeLogs?id=${userId}&&timezone=${timeZone}&&time=${time}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const data = await response.json()
        if (response.ok) {
          dispatch({ type: Types.USERS_TIME_LOG_SUCCESS, payload: data })
        } else if (response.status === 401) {
          dispatch({ type: Types.USERS_TIME_LOG_FAIL })
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: data.message
            })
          )
        }
      } catch (error) {
        dispatch({ type: Types.USERS_TIME_LOG_FAIL })
        dispatch(messages.defaultNetworkError('Loading users...'))
      }
    }
  },
  getCurrentTimeLog: (token) => {
    return async (dispatch) => {
      // dispatch({ type: Types.USERS_LOADING_REQUEST })
      let timeZone = new Date().getTimezoneOffset()
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/user/getCurrentTimeLog?timezone=${timeZone}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const data = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.USERS_CURRENT_TIME_LOG_SUCCESS,
            payload: data
          })
        } else if (response.status === 401) {
          dispatch({ type: Types.USERS_CURRENT_TIME_LOG_FAIL })
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: data.message
            })
          )
        }
      } catch (error) {
        dispatch({ type: Types.USERS_CURRENT_TIME_LOG_FAIL })
        dispatch(messages.defaultNetworkError('Loading users...'))
      }
    }
  },
  updateUser: (user, id, token) => {
    return async (dispatch) => {
      dispatch({ type: Types.USER_UPDATE_REQUEST, payload: { userId: id } })
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/user/${id}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify(user)
          }
        )
        const result = await response.json()
        if (response.ok) {
          if (user.photo) {
            const photo = user.photo.data
            user.photo = photo
          }

          dispatch({
            type: Types.USER_UPDATE_SUCCESS,
            payload: { user, id }
          })
        } else if (response.status === 401) {
          dispatch({ type: Types.USERS_LOADING_FAIL })
          dispatch(auth.logout(token))
        } else {
          dispatch({ type: Types.USER_UPDATE_FAIL })
          return dispatch(
            messages.setMessage({
              status: response.status,
              message: result.error.message
            })
          )
        }
        dispatch(
          messages.setMessage({
            status: response.status,
            message: result.message
          })
        )
        return response.ok
      } catch (error) {
        dispatch({ type: Types.USER_UPDATE_FAIL })
        dispatch(messages.defaultNetworkError())
      }
    }
  },

  deleteUser: (id, token) => {
    return async (dispatch) => {
      dispatch({ type: Types.USER_DELETE_REQUEST, payload: { userId: id } })
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/user/${id}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const result = await response.json()
        if (response.ok) {
          // dispatch(Creators.getAllUsers(token))
          dispatch({ type: Types.USER_DELETE_SUCCESS, payload: { id } })
        } else if (response.status === 401) {
          dispatch({ type: Types.USERS_LOADING_FAIL })
          dispatch(auth.logout(token))
        } else {
          dispatch({ type: Types.USER_DELETE_FAIL })
        }
        dispatch(
          messages.setMessage({
            status: response.status,
            message: result.message
          })
        )
      } catch (error) {
        dispatch({ type: Types.USER_DELETE_FAIL })
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  resetKey: (id, token) => {
    return async (dispatch) => {
      dispatch({ type: Types.USER_RESET_KEY_REQUEST })
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/user/resetkey/${id}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({ type: Types.USER_RESET_KEY_SUCCESS })
        } else if (response.status === 401) {
          dispatch({ type: Types.USERS_LOADING_FAIL })
          dispatch(auth.logout(token))
        } else {
          dispatch({ type: Types.USER_RESET_KEY_FAIL })
        }
        dispatch(
          messages.setMessage({
            status: response.status,
            message: result.message
          })
        )
      } catch (error) {
        dispatch({ type: Types.USER_RESET_KEY_FAIL })
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  updateUsersDocuments: (user, id) => {
    return async (dispatch) => {
      dispatch({
        type: Types.USER_UPDATE_SUCCESS,
        payload: { user, id }
      })
    }
  },
  searchUsers: (searchResults) => {
    return async (dispatch) => {
      dispatch({
        type: Types.USERS_LOADING_SEARCH_SUCCESS,
        payload: searchResults
      })
    }
  },
  getUserSalaries: (id, token, page, limit) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/user/getSalaryList/${id}?page=${page}&limit=${limit}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.USER_GET_SALARIES,
            payload: { result, id, page }
          })
        } else if (response.status === 401) {
          dispatch(auth.logout(token))
        } else {
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
          throw new Error('throw')
        }
        dispatch(
          messages.setMessage({
            status: response.status,
            message: result.message
          })
        )
        return response.ok
      } catch (error) {
        if (error && error.message === 'throw') {
          return
        }
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  addUserSalary: (salary, id, token) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/user/addUserSalary/${id}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify(salary)
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.USER_ADD_SALARIES,
            payload: { result: result.data, id }
          })
        } else if (response.status === 401) {
          dispatch(auth.logout(token))
        } else {
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
          throw new Error('throw')
        }
        dispatch(
          messages.setMessage({
            status: response.status,
            message: result.message
          })
        )
        return response.ok
      } catch (error) {
        if (error && error.message === 'throw') {
          return
        }
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  deleteUserSalary: (billId, salId, userId, token) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/user/deleteUserSalary/${salId}/${billId}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.USER_DELETE_SALARIES,
            payload: { result: result.data, userId, billId, salId }
          })
        } else if (response.status === 401) {
          dispatch(auth.logout(token))
        } else {
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
          throw new Error('throw')
        }
        dispatch(
          messages.setMessage({
            status: response.status,
            message: result.message
          })
        )
        return response.ok
      } catch (error) {
        if (error && error.message === 'throw') {
          return
        }
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  editSalaryBill: (bill, id, token) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/user/editSalary/${id}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify(bill)
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.USER_EDIT_SALARIES,
            payload: { result: result.data, id }
          })
        } else if (response.status === 401) {
          dispatch(auth.logout(token))
        } else {
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
          throw new Error('throw')
        }
        dispatch(
          messages.setMessage({
            status: response.status,
            message: result.message
          })
        )
        return response.ok
      } catch (error) {
        if (error && error.message === 'throw') {
          return
        }
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  getAllUsersIdentitiesList: (
    token,
    page = 1,
    search = '',
    limit = 20,
    active = false
  ) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/user/getUsersIds/`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify({
              search,
              page,
              limit,
              active
            })
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.USERS_ALL_LIST_IDENTITIES,
            payload: { data: result.users }
          })
        } else if (response.status === 401) {
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        }
        return response.ok
      } catch (error) {
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  getUsersJobList: (token, page = 1, limit = 10, userId) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/job/getPersonalJobsList?page=${page}&limit=${limit}&uid=${userId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.USER_GET_JOBS,
            payload: { result, page }
          })
        } else if (response.status === 401) {
          dispatch(auth.logout(token))
        } else {
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
          throw new Error('throw')
        }
        // dispatch(
        //   messages.setMessage({
        //     status: response.status,
        //     message: result.message
        //   })
        // )
        return response.ok
      } catch (error) {
        if (error && error.message === 'throw') {
          return
        }
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  addCandidateInsideJob: (id, candidate, token, user) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/job/addCandidate/${id}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify(candidate)
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.CANDIDATE_ADD_JOB,
            payload: { result: result.data, id, user }
          })
        } else if (response.status === 401) {
          dispatch(auth.logout(token))
        } else {
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
          throw new Error('throw')
        }
        dispatch(
          messages.setMessage({
            status: response.status,
            message: result.message
          })
        )
        return response.ok
      } catch (error) {
        if (error && error.message === 'throw') {
          return
        }
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  updateCandidateInsideJob: (id, candidate, token, user) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/job/updateCandidate/${id}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify(candidate)
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.CANDIDATE_UPDATE_JOB,
            payload: {
              result: {
                stage: result.data.stage,
                meetDate: result.data.meetDate,
                meetTime: result.data.meetTime,
                timeZone: result.data.timeZone,
                _id: result.data._id
              },
              id,
              user
            }
          })
        } else if (response.status === 401) {
          dispatch(auth.logout(token))
        } else {
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
          throw new Error('throw')
        }
        dispatch(
          messages.setMessage({
            status: response.status,
            message: result.message
          })
        )
        return response.ok
      } catch (error) {
        if (error && error.message === 'throw') {
          return
        }
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  deleteCandidateInsideJob: (id, candidate, token, user) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/job/deleteCandidate/${id}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify(candidate)
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.CANDIDATE_DELETE_JOB,
            payload: { result: result, id, user }
          })
        } else if (response.status === 401) {
          dispatch(auth.logout(token))
        } else {
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
          throw new Error('throw')
        }
        dispatch(
          messages.setMessage({
            status: response.status,
            message: result.message
          })
        )
        return response.ok
      } catch (error) {
        if (error && error.message === 'throw') {
          return
        }
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  findProtocolsCount:
    (token, timeZone, period, page, limit) => async (dispatch) => {
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_SERVER_API
          }/user/protocols/${timeZone.replace(
            '/',
            '-'
          )}?period=${period}&page=${page}&limit=${limit}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const data = await response.json()
        if (response.ok) {
          dispatch({ type: Types.PROTOCOL_COUNT, payload: data })
        } else if (response.status === 401) {
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: data.message
            })
          )
        }
      } catch (error) {
        dispatch(messages.defaultNetworkError('Loading users...'))
      }
    },
  adminFilterUser: (userId) => async (dispatch) => {
    dispatch({
      type: Types.ADMIN_FILTER_USER,
      payload: { userId }
    })
  }
}
