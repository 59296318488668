export const PATHS = {
  ADMINUSER: '/adminuser',
  ADMINTAG: '/admintag',
  UNTERNEHMEN: '/unternehmen',
  KANDIDATEN: '/kandidaten'
}

export const FILE_URL = {
  company: process.env.REACT_APP_SERVER_API + '/company/file/',
  candidate: process.env.REACT_APP_SERVER_API + '/candidate/file/',
  user: process.env.REACT_APP_SERVER_API + '/user/'
}
