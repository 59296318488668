/* eslint-disable */
import { Creators as messages } from './statusMessages'
import { Creators as auth } from './auth'
import axios from '../../intercepters/axios'

export const Types = {
  CANDIDATE_RELOAD: 'candidates/CANDIDATE_RELOAD',
  CANDIDATE_REGISTER_REQUEST: 'candidates/CANDIDATE_REGISTER_REQUEST',
  CANDIDATE_REGISTER_SUCCESS: 'candidates/CANDIDATE_REGISTER_SUCCESS',
  CANDIDATE_REGISTER_FAIL: 'candidates/CANDIDATE_REGISTER_FAIL',
  CANDIDATE_UPDATE_REQUEST: 'candidates/CANDIDATE_UPDATE_REQUEST',
  CANDIDATE_UPDATE_SUCCESS: 'candidates/CANDIDATE_UPDATE_SUCCESS',
  CANDIDATE_UPDATE_FAIL: 'candidates/CANDIDATE_UPDATE_FAIL',
  CANDIDATE_DELETE_REQUEST: 'candidates/CANDIDATE_DELETE_REQUEST',
  CANDIDATE_DELETE_SUCCESS: 'candidates/CANDIDATE_DELETE_SUCCESS',
  CANDIDATE_DELETE_FAIL: 'candidates/CANDIDATE_DELETE_FAIL',
  CANDIDATE_RESET_KEY_REQUEST: 'candidates/CANDIDATE_RESET_KEY_REQUEST',
  CANDIDATE_RESET_KEY_SUCCESS: 'candidates/CANDIDATE_RESET_KEY_SUCCESS',
  CANDIDATE_RESET_KEY_FAIL: 'candidates/CANDIDATE_RESET_KEY_FAIL',
  CANDIDATE_LOADING_REQUEST: 'candidates/CANDIDATE_LOADING_REQUEST',
  CANDIDATE_LOADING_SUCCESS: 'candidates/CANDIDATE_LOADING_SUCCESS',
  CANDIDATE_LOADING_REST_SUCCESS: 'candidates/CANDIDATE_LOADING_REST_SUCCESS',
  CANDIDATE_LOADING_SUCCESS_ONE: 'candidates/CANDIDATE_LOADING_SUCCESS_ONE',
  CANDIDATE_LOADING_FAIL: 'candidates/CANDIDATE_LOADING_FAIL',
  CANDIDATE_LOADING_SEARCH_SUCCESS:
    'candidates/CANDIDATE_LOADING_SEARCH_SUCCESS',
  CANDIDATE_SEARCH_PAGINATION_SUCCESS:
    'candidates/CANDIDATE_SEARCH_PAGINATION_SUCCESS',
  CANDIDATE_LIST_LOADING_REQUEST: 'candidates/CANDIDATE_LIST_LOADING_REQUEST',
  CANDIDATE_LIST_LOADING_SUCCESS: 'candidates/CANDIDATE_LIST_LOADING_SUCCESS',
  CANDIDATE_LIST_LOADING_FAIL: 'candidates/CANDIDATE_LIST_LOADING_FAIL',
  COMPANY_UPDATE_SEARCH_STATE: 'companies/COMPANY_UPDATE_SEARCH_STATE',
  CANDIDATE_DSGVO_EMAIL_VERIFICATION_LINK:
    'candidates/CANDIDATE_DSGVO_EMAIL_VERIFICATION_LINK',
  CANDIDATE_DSGVO_VERIFICATION: 'candidates/CANDIDATE_DSGVO_VERIFICATION',
  CANDIDATES_ALL_LIST_IDENTITIES: 'candidates/CANDIDATES_ALL_LIST_IDENTITIES'
}

// Reducer
const INITIAL_STATE = {
  isLoading: false,
  candidateUpdate: {},
  candidates: [],
  search: [],
  kunden: false,
  wishlist: false,
  dsgvo: false,
  page: 1,
  currentCandidate: {},
  rData: {},
  candidatesSearch: {},
  candidateIdentites: [],
  candidatesList: {
    list: [],
    totalCount: 0,
    page: 0
  },
  pagCandidates: {},
  showList: false,
  newCandidate: null
}

export default function reducer(state = INITIAL_STATE, action) {
  const allCandidates = state.candidates
  switch (action.type) {
    case Types.CANDIDATE_REGISTER_REQUEST:
    case Types.CANDIDATE_LOADING_REQUEST:
    case Types.CANDIDATE_UPDATE_REQUEST:
    case Types.CANDIDATE_DELETE_REQUEST:
    case Types.CANDIDATE_RESET_KEY_REQUEST:
      return {
        ...state,
        newCandidate: null,
        isLoading: true
      }
    case Types.CANDIDATE_LIST_LOADING_REQUEST:
      return {
        ...state,
        showList:
          action.payload.page == 1 && action.payload.performSearch
            ? false
            : true,
        isLoading: false,
        kunden: action.payload.kunden,
        dsgvo: action.payload.dsgvo,
        page: action.payload.page,
        search: action.payload.search,
        wishlist: action.payload.wishlist
      }

    case Types.CANDIDATE_REGISTER_SUCCESS:
      state.candidates.unshift(action.payload.data)
      state.candidatesList.list.unshift(action.payload.data)
      return {
        ...state,
        newCandidate: action.payload.newCandidate,
        candidates: [...state.candidates],
        rData: action.payload.newCandidate,
        candidatesList: {
          list: [...state.candidatesList.list],
          totalCount: state.candidatesList.totalCount + 1,
          page: state.candidatesList.page
        },
        isLoading: false
      }
    case Types.CANDIDATE_RELOAD:
      return {
        ...state,
        rData: action.payload.newCandidate,
        isLoading: false
      }
    case Types.CANDIDATE_LOADING_SEARCH_SUCCESS:
      return {
        ...state,
        candidatesSearch: action.payload,
        newCandidate: null,
        isLoading: false
      }
    case Types.CANDIDATE_LIST_LOADING_SUCCESS:
      // list: (action.payload.page === 1) ? [...action.payload.data] : [...state.candidatesList.list, ...action.payload.data],
      return {
        ...state,
        candidatesList: {
          list: [...action.payload.data],
          totalCount: action.payload.totalCount,
          page: action.payload.page
        },
        isLoading: false,
        showList: true
      }
    case Types.CANDIDATE_LOADING_SUCCESS:
      return {
        ...state,
        candidates: action.payload,
        newCandidate: null,
        isLoading: false
      }

    case Types.CANDIDATE_LOADING_REST_SUCCESS:
      action.payload.map((candidate) => {
        if (
          state.candidates.find((x) => x._id === candidate._id) === undefined
        ) {
          allCandidates.push(candidate)
        }
        return candidate
      })
      return {
        ...state,
        candidates: allCandidates,
        newCandidate: null,
        isLoading: false
      }

    case Types.CANDIDATE_LOADING_SUCCESS_ONE:
      return {
        ...state,
        newCandidate: null,
        currentCandidate: action.data,
        isLoading: false
      }

    case Types.CANDIDATE_SEARCH_PAGINATION_SUCCESS:
      return {
        ...state,
        newCandidate: null,
        pagCandidates: action.payload
      }
    case Types.CANDIDATE_UPDATE_SUCCESS: {
      return {
        ...state,
        candidates: state.candidates.map((candidate) => {
          if (candidate._id === action.payload.id) {
            return {
              ...candidate,
              ...action.payload.candidate
            }
          }

          return candidate
        }),
        candidatesList: {
          ...state.candidatesList,
          list: state.candidatesList.list.map((candidate) => {
            if (candidate._id === action.payload.id) {
              return {
                ...candidate,
                ...action.payload.candidate
              }
            }

            return candidate
          })
        }
      }
    }
    case Types.CANDIDATE_RESET_KEY_SUCCESS:
      return {
        ...state,
        candidates: state.candidates.map((candidate) =>
          candidate._id === action.payload.id
            ? {
                ...candidate,
                ...action.payload.candidate
              }
            : candidate
        ),
        newCandidate: null,
        isLoading: false
      }

    case Types.CANDIDATE_DELETE_SUCCESS:
      return {
        ...state,
        candidates: [
          ...state.candidates.filter(
            (candidate) => candidate._id !== action.payload.id
          )
        ],
        candidatesList: {
          list: [
            ...state.candidatesList.list.filter(
              (candidate) => candidate._id !== action.payload.id
            )
          ],
          totalCount: state.candidatesList.totalCount - 1,
          page: state.candidatesList.page
        },
        newCandidate: null,
        isLoading: false
      }
    case Types.CANDIDATES_ALL_LIST_IDENTITIES: {
      return {
        ...state,
        candidateIdentites: [...action.payload.data]
      }
    }
    case Types.CANDIDATE_REGISTER_FAIL:
    case Types.CANDIDATE_LOADING_FAIL:
    case Types.CANDIDATE_UPDATE_FAIL:
    case Types.CANDIDATE_DELETE_FAIL:
    case Types.CANDIDATE_RESET_KEY_FAIL:
      return {
        ...state,
        newCandidate: null,
        isLoading: false
      }

    default:
      return state
  }
}

export const Creators = {
  addCandidate: (newCandidate, token) => {
    return async (dispatch) => {
      dispatch({
        type: Types.CANDIDATE_REGISTER_REQUEST
      })
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/candidate`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify(newCandidate)
          }
        )
        const result = await response.json()
        if (response.ok) {
          if (newCandidate.photo) {
            const photo = newCandidate.photo.data
            newCandidate.photo = photo
          }
          dispatch({
            type: Types.CANDIDATE_REGISTER_SUCCESS,
            payload: {
              data: {
                ...newCandidate,
                ...{ _id: result.id, code: result.code }
              },
              newCandidate: result.data
            }
          })
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else if (response.status === 401) {
          dispatch({
            type: Types.USERS_LOADING_FAIL
          })
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else {
          dispatch({
            type: Types.CANDIDATE_REGISTER_FAIL
          })
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        }
        return result
      } catch (error) {
        dispatch({
          type: Types.CANDIDATE_REGISTER_FAIL
        })
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  reloadCandidate: (newCandidate, token) => {
    return async (dispatch) => {
      dispatch({
        type: Types.CANDIDATE_RELOAD,
        newCandidate: newCandidate
      })
    }
  },

  getCandidates: (searchCriteria, token) => {
    return async (dispatch) => {
      dispatch({
        type: Types.CANDIDATE_LOADING_REQUEST
      })
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/candidate`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.CANDIDATE_LOADING_SUCCESS,
            payload: result.result
          })
        } else if (response.status === 401) {
          dispatch({
            type: Types.CANDIDATE_LOADING_FAIL
          })
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else {
          dispatch({
            type: Types.CANDIDATE_LOADING_FAIL
          })
        }
      } catch (error) {
        dispatch({
          type: Types.CANDIDATE_LOADING_FAIL
        })
        dispatch(messages.defaultNetworkError())
      }
    }
  },

  getRestCandidates: (searchCriteria, token, picture = true) => {
    return async (dispatch) => {
      // dispatch({ type: Types.CANDIDATE_LOADING_REQUEST })
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/candidate/rest?picture=${picture}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.CANDIDATE_LOADING_REST_SUCCESS,
            payload: result.result
          })
        } else if (response.status === 401) {
          dispatch({
            type: Types.CANDIDATE_LOADING_FAIL
          })
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else {
          dispatch({
            type: Types.CANDIDATE_LOADING_FAIL
          })
        }
      } catch (error) {
        dispatch({
          type: Types.CANDIDATE_LOADING_FAIL
        })
        dispatch(messages.defaultNetworkError())
      }
    }
  },

  getCandidate: (id, token) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/candidate/one/${id}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.CANDIDATE_LOADING_SUCCESS_ONE,
            data: result.result
          })
          return result.result
        } else if (response.status === 401) {
          dispatch({
            type: Types.CANDIDATE_LOADING_FAIL
          })
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else {
          dispatch({
            type: Types.CANDIDATE_LOADING_FAIL
          })
        }
      } catch (error) {
        dispatch({
          type: Types.CANDIDATE_LOADING_FAIL
        })
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  addProtocol: (protocol, token, socket) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/candidate/addProtocols`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify(protocol)
          }
        )
        const result = await response.json()
        if (socket) {
          socket.emit('createReminder')
        }
      } catch (error) {
        dispatch({
          type: Types.CANDIDATE_UPDATE_FAIL
        })
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  updateProtocol: (protocol, token) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/candidate/updateProtocol`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify(protocol)
          }
        )
        const result = await response.json()

        return result
      } catch (error) {
        dispatch({
          type: Types.CANDIDATE_UPDATE_FAIL
        })
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  updateCandidate: (candidate, id, token) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/candidate/${id}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify(candidate)
          }
        )
        const result = await response.json()
        if (response.ok) {
          if (candidate.photo) {
            const photo = candidate.photo.data
            candidate.photo = photo
          }
          dispatch({
            type: Types.CANDIDATE_UPDATE_SUCCESS,
            payload: { candidate, id }
          })
        } else if (response.status === 401) {
          dispatch({
            type: Types.CANDIDATE_UPDATE_FAIL
          })
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else {
          dispatch({
            type: Types.CANDIDATE_UPDATE_FAIL
          })
          dispatch(
            messages.setMessage({
              status: '401',
              message: result.message
            })
          )
        }
        return result
      } catch (error) {
        dispatch({
          type: Types.CANDIDATE_UPDATE_FAIL
        })
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  updateCandidateProfile: (data, id, token) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/candidate/profile/${id}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify(data)
          }
        )
        const result = await response.json()

        if (response.ok) {
          if (data.photo) {
            const photo = data.photo.data
            data.photo = photo
          }
          dispatch({
            type: Types.CANDIDATE_UPDATE_SUCCESS,
            payload: { candidate: response.result, id }
          })
        } else if (response.status === 401) {
          dispatch({
            type: Types.CANDIDATE_UPDATE_FAIL
          })
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else {
          dispatch({
            type: Types.CANDIDATE_UPDATE_FAIL
          })
        }
        return result
      } catch (error) {
        dispatch({
          type: Types.CANDIDATE_UPDATE_FAIL
        })
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  updateCandidateKQFiles: (candidate, id) => {
    return async (dispatch) => {
      dispatch({
        type: Types.CANDIDATE_UPDATE_SUCCESS,
        payload: { candidate, id }
      })
    }
  },

  deleteCandidate: (id, token) => {
    return async (dispatch) => {
      dispatch({
        type: Types.CANDIDATE_DELETE_REQUEST
      })
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/candidate/${id}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.CANDIDATE_DELETE_SUCCESS,
            payload: { id }
          })
        } else if (response.status === 401) {
          dispatch({
            type: Types.CANDIDATE_DELETE_FAIL
          })
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else {
          dispatch({
            type: Types.CANDIDATE_DELETE_FAIL
          })
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        }
        return response.ok
      } catch (error) {
        dispatch({
          type: Types.CANDIDATE_DELETE_FAIL
        })
        dispatch(messages.defaultNetworkError())
      }
    }
  },

  resetKey: (id, token) => {
    return async (dispatch) => {
      dispatch({
        type: Types.CANDIDATE_RESET_KEY_REQUEST
      })
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/CANDIDATE/resetkey/${id}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.CANDIDATE_RESET_KEY_SUCCESS,
            payload: {
              candidate: {
                password: result.password
              },
              id
            }
          })
        } else if (response.status === 401) {
          dispatch({
            type: Types.CANDIDATE_LOADING_FAIL
          })
          dispatch(auth.logout(token))
        } else {
          dispatch({
            type: Types.CANDIDATE_RESET_KEY_FAIL
          })
        }
        dispatch(
          messages.setMessage({
            status: response.status,
            message: result.message
          })
        )
        return true
      } catch (error) {
        dispatch({
          type: Types.CANDIDATE_RESET_KEY_FAIL
        })
        dispatch(messages.defaultNetworkError())
      }
    }
  },

  searchCandidates: (searchResults) => {
    return async (dispatch) => {
      dispatch({
        type: Types.CANDIDATE_LOADING_SEARCH_SUCCESS,
        payload: searchResults
      })
    }
  },

  setRestPagination: (page, token) => {
    return async (dispatch) => {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_API}/candidate/test/?page=${page}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        .then((res) => {
          dispatch({
            type: Types.CANDIDATE_SEARCH_PAGINATION_SUCCESS,
            payload: res.data
          })
        })
        .catch((err) => console.error(err.message))
    }
  },
  getCandidatesList: (
    token,
    page = 1,
    search = '',
    kunden = false,
    performSearch,
    dsgvo = false,
    wishlist = false
  ) => {
    return async (dispatch) => {
      dispatch({
        type: Types.CANDIDATE_LIST_LOADING_REQUEST,
        payload: { page, search, kunden, performSearch, dsgvo, wishlist }
      })
      if (!performSearch) return
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/candidate/getCandidateLists/`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify({
              search,
              page,
              kunden,
              dsgvo,
              wishlist
            })
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.CANDIDATE_LIST_LOADING_SUCCESS,
            payload: result.result
          })
        } else if (response.status === 401) {
          dispatch({
            type: Types.CANDIDATE_LIST_LOADING_FAIL
          })
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else {
          dispatch({
            type: Types.COMPANY_LIST_LOADING_FAIL
          })
        }
      } catch (error) {
        console.log(error)
        dispatch({
          type: Types.COMPANY_LIST_LOADING_FAIL
        })
        dispatch(messages.defaultNetworkError())
      }
    }
  },

  sendDSGVOVerificaitonEmail: (id, token) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/candidate/sentVerificationEmail/${id}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const result = await response.json()
        dispatch(
          messages.setMessage({
            status: response.status,
            message: result.message
          })
        )
      } catch (error) {
        dispatch(messages.defaultNetworkError())
      }
    }
  },

  verifyDSGVOEmail: (token) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/candidate/verifyCandidateEmail`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token })
          }
        )
        const result = await response.json()
        return { result, status: response.status }
      } catch (error) {
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  getAllCandidatesIdentitiesList: (
    token,
    page = 1,
    search = '',
    limit = 20,
    active = false
  ) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/candidate/getCandidatesIds/`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify({
              search,
              page,
              limit,
              active
            })
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.CANDIDATES_ALL_LIST_IDENTITIES,
            payload: { data: result.candidates }
          })
        } else if (response.status === 401) {
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        }
        return result.candidates
      } catch (error) {
        dispatch(messages.defaultNetworkError())
      }
    }
  }
}
