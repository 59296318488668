import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ProgressSpinner } from 'primereact/progressspinner'
import { Dialog } from 'primereact/dialog'

function Spinner({
  authState,
  usersState,
  companiesState,
  show,
  managersState
}) {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (
      authState.isLoading ||
      usersState.isLoading ||
      companiesState.isLoading ||
      managersState.isLoading ||
      show
    ) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }, [
    authState.isLoading,
    usersState.isLoading,
    companiesState.isLoading,
    managersState.isLoading
  ])

  return (
    <Dialog
      className="modal-spinner"
      visible={visible}
      modal={true}
      focusOnShow={false}
      showHeader={false}
      onHide={() => setVisible(false)}
    >
      <ProgressSpinner />
    </Dialog>
  )
}

Spinner.propTypes = {
  authState: PropTypes.object,
  usersState: PropTypes.object,
  companiesState: PropTypes.object,
  managersState: PropTypes.object,
  show: PropTypes.bool
}

const mapStateToProps = (state) => ({
  authState: state.authReducer,
  usersState: state.usersReducer,
  companiesState: state.companiesReducer,
  managersState: state.managersReducer
})

export default connect(mapStateToProps, null)(Spinner)
