import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Creators as TabmenuActions } from '../../redux/ducks/tabmenu'
import { TabMenu } from 'primereact/tabmenu'
import { Creators as addDataPanelActions } from '../../redux/ducks/addDataPanel'
import { PATHS } from '../../helpers/constants'

function Tabmenu({ tabmenuState, tabmenuActions, addDataPanelOpen }) {
  const location = useLocation()
  const history = useHistory()
  const { model, activeTab } = tabmenuState
  // model[1].command = () => {
    // history.push(PATHS.UNTERNEHMEN, { action: 'OPEN_ADD_PANEL' })
    // addDataPanelOpen(PATHS.UNTERNEHMEN)
  // }

  // model[3].command = () => {
    // history.push(PATHS.KANDIDATEN, { action: 'OPEN_ADD_PANEL' })
    // addDataPanelOpen(PATHS.KANDIDATEN)
  // }

  const { setSelectedTab } = tabmenuActions

  useEffect(() => {
    setSelectedTab(location.pathname)
  }, [location, setSelectedTab])

  const handleActiveTab = (urlPath) => {
    history.push(urlPath)
  }
  return (
    <TabMenu
      id="topbar-tabmenu"
      model={model}
      activeItem={activeTab}
      onTabChange={(e) => handleActiveTab(e.value.path)}
    />
  )
}

Tabmenu.propTypes = {
  tabmenuState: PropTypes.object.isRequired,
  tabmenuActions: PropTypes.object.isRequired,
  addDataPanelOpen: PropTypes.func.isRequired
}

// State mapping
const mapStateToProps = (state) => ({
  tabmenuState: state.tabmenuReducer
})

// Dispatch mapping
const mapDispatchToProps = (dispatch) => ({
  tabmenuActions: bindActionCreators(TabmenuActions, dispatch),
  addDataPanelOpen: (path) => dispatch(addDataPanelActions.openPanel(path))
})

export default connect(mapStateToProps, mapDispatchToProps)(Tabmenu)
