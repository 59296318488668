import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import authReducer from './ducks/auth'
import statusMessagesReducer from './ducks/statusMessages'
import tabmenuReducer from './ducks/tabmenu'
import addDataPanelReducer from './ducks/addDataPanel'
import slideDownPanelReducer from './ducks/slideDownPanel'
import usersReducer from './ducks/users'
import tagsReducer from './ducks/tags'
import pdfUploadReducer from './ducks/pdfUpload'
import companiesReducer from './ducks/companies'
import companyOptionReducer from './ducks/companyOption'
import candidatesReducer from './ducks/candidates'
import searchReducer from './ducks/searchTerms'
import managersReducer from './ducks/managers'
import candidateRadarReducer from './ducks/candidateRadar'
import dealReducer from './ducks/profitCenter'

// Dev tools
import { composeWithDevTools } from 'redux-devtools-extension'

const combinedReducers = combineReducers({
  authReducer,
  statusMessagesReducer,
  tabmenuReducer,
  addDataPanelReducer,
  usersReducer,
  tagsReducer,
  pdfUploadReducer,
  companiesReducer,
  slideDownPanelReducer,
  companyOptionReducer,
  candidatesReducer,
  searchReducer,
  managersReducer,
  candidateRadarReducer,
  dealReducer
})

const store = createStore(
  combinedReducers,
  composeWithDevTools(applyMiddleware(thunk))
)

export default store
