import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'

import App from '../src/App/App'
import './layout/style.scss'
// import 'bootstrap/dist/css/bootstrap.min.css';

// Redux stores
import store from './redux/store'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

root.render(
  <Router>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>
)
