/* eslint-disable */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import Main from './Main'
import { bindActionCreators } from 'redux'
import { Creators as authActions } from '../redux/ducks/auth'
import { Creators as tagActions } from '../redux/ducks/tags'
import { Creators as candidatesActions } from '../redux/ducks/candidates'
import { Creators as userActions, Types } from '../redux/ducks/users'
import { Creators as companyActions } from '../redux/ducks/companies'
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import { io } from 'socket.io-client'
/* Components */
import { Message } from 'primereact/message'
import Topbar from '../navigation/Topbar/Topbar'
import ToastMessage from '../components/ToastMessage'
import ProgressSpinnerCustom from '../components/Spinner/Spinner'
// import IdleTimer from './idleTimer'
import { ProgressSpinner } from 'primereact/progressspinner'
import { unRegister } from '../intercepters/fetch'
import { useIdleTimer } from 'react-idle-timer'
import { getToken, getUser } from '../helpers/jwt'
import { useHistory } from 'react-router-dom'

function App({
  auth,
  authActions,
  getAllTags,
  getAllUsersList,
  candidatesActions,
  getAllUsers,
  companyActions,
  getCurrentLogTime,
  getAllCandidatesIdentitiesList,
  getAllUsersIdentitiesList
}) {
  const dispatch = useDispatch()
  const history = useHistory()

  const { user, token, expiresIn, createdAt } = auth
  const { logout, sessionValidator, updateTimeoutId, jwtInitializer } =
    authActions
  const [socket, setSocket] = useState(null)

  // const [socketId, setSocketId] = useState(null);
  const [tagsLoading, setTagsLoading] = useState(false)
  // let timer
  // const [showMessage, setShowMessage] = useState(false)
  // const [sessionRemaingTime, setSessionRemaingTime] = useState({
  //   minutes: '1',
  //   seconds: '00'
  // })

  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    // Let's not do anything on sitting idle
    // if(token || user) {
    //   dispatch(logout(token))
    //   .then(async () => {
    //     await dispatch(localLogout())
    //     history.push('/')
    //   }).catch(async () => {
    //     await dispatch(localLogout())
    //     history.push('/')
    //   })
    // }
  }

  const { reset } = useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 30,
    promptTimeout: 0,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange'
    ],
    immediateEvents: [],
    debounce: 500,
    startOnMount: true,
    crossTab: true,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false
  })

  useEffect(() => {
    jwtInitializer().catch(() => console.warn('Not Authorized'))
    return () => {
      unRegister()
    }
  }, [])

  useEffect(() => {
    if (token) {
      let socketCon = io(process.env.REACT_APP_SOCKET_SERVER_API, {
        param: token
      })
      socketCon.on('connect', () => {
        // setSocketId(socketCon.id)
        setSocket(socketCon)
      })

      socketCon.on('disconnect', () => {
        // setSocketId(null)
        setSocket(null)
      })

      socketCon.on('logout', (userId) => {
        if (user._id === userId) {
          logout(token)
        }
      })

      return () => {
        socketCon.off('connect')
        socketCon.off('disconnect')
      }
    }
  }, [token])

  const intitalizeNotificationsSocket = (data) => {
    if (user != null && data.user_id === user._id) {
      authActions.createNotification(token)
      authActions.notificationGetEmit('show_bell')
    }
  }

  const intitalizeLogoutSocket = (data) => {
    //remember when user is logged data socket is heard from backend and the users list is fetched
    //for miterbieter section
    if (token && token != null && user && user.admin) {
      dispatch({
        type: Types.USER_UPDATE_SUCCESS,
        payload: {
          user: { _id: data.userId, isLoggedIn: false },
          id: data.userId
        }
      })
    }
  }

  const intitalizeLoginSocket = (data) => {
    if (token && token != null && user && user.admin && user._id != data.id) {
      dispatch({
        type: Types.USER_UPDATE_SUCCESS,
        payload: { user: { _id: data.id, isLoggedIn: true }, id: data.id }
      })
    }
  }

  useEffect(() => {
    if (socket) {
      authActions.setSocket(socket)
      socket.on('getNotifications', intitalizeNotificationsSocket)
      socket.on('logout', intitalizeLogoutSocket)
      socket.on('login', intitalizeLoginSocket)

      return () => {
        socket.off('getNotifications', intitalizeNotificationsSocket)
        socket.off('logout', intitalizeLoginSocket)
        socket.off('login', intitalizeLogoutSocket)
      }
    }
  }, [socket])
  // Validates if there is a previous active session.
  // useEffect(() => {
  //   sessionValidator()
  // }, [sessionValidator])

  // Sets the session timer.
  useEffect(() => {
    if (token) {
      // Calculates the session's remaining time.
      // const remainingTime =
      //   parseInt(createdAt) + parseInt(expiresIn) - new Date().getTime()

      // Counts the session remainig time
      // setTimeout(() => {
      //   setShowMessage(true)
      //   timeCounter()
      // }, [remainingTime])

      // Load app configs
      getTagHandler(token)
      getAllUsers(auth.token)
      // getCurrentLogTime(auth.token)
      getAllCandidatesIdentitiesList(token, 1, '', 100, false)
      getAllUsersIdentitiesList(token, 1, '', 1000, true)

      // candidatesActions.setRestPagination(1, token)
      companyActions.getCompaniesList(token, 1, [], false, true)
      candidatesActions.getCandidatesList(token, 1, [], false, true)
    }
    return () => {
      // clearTimeout()
      // resetTimeCounter()
    }
  }, [expiresIn])

  const getTagHandler = (token) => {
    setTagsLoading(true)
    getAllTags(token)
      .then(() => setTagsLoading(false))
      .catch(() => setTagsLoading(false))
  }
  // Countdown timer.
  // const timeCounter = () => {
  //   const clock = [sessionRemaingTime.minutes, sessionRemaingTime.seconds]
  //   let timeLimit = 60

  //   timer = setInterval(() => {
  //     if (--clock[1] < 0) {
  //       clock[0]--
  //       clock[1] = 59
  //     }
  //     setSessionRemaingTime({
  //       ...sessionRemaingTime,
  //       minutes: clock[0],
  //       seconds: clock[1] < 10 ? '0' + clock[1] : clock[1]
  //     })

  //     // After the time ends.
  //     if (timeLimit-- < 1) {
  //       resetTimeCounter()
  //       logout()
  //     }
  //   }, 1000)
  // }

  // const resetTimeCounter = () => {
  //   clearInterval(timer)
  //   setShowMessage(false)
  //   setSessionRemaingTime({
  //     ...sessionRemaingTime,
  //     minutes: '1',
  //     seconds: '00'
  //   })
  // }

  useEffect(() => {
    // Calculate the time until midnight
    const now = new Date()
    const midnight = new Date(now)
    midnight.setHours(24, 0, 0, 0)
    const timeUntilMidnight = midnight - now

    // Set up a timer to log out at midnight
    const logoutTimer = setTimeout(() => {
      // Perform logout action here
      logout(token)
    }, timeUntilMidnight)

    // Clear the timer when the component unmounts
    return () => clearTimeout(logoutTimer)
  }, [])

  if (tagsLoading) {
    return (
      <div className="main-spinner">
        <ProgressSpinner />
      </div>
    )
  }

  return (
    <div id="app">
      {/* {showMessage && (
        <Message
          id="session-time-msg"
          severity="warn"
          text={`Ihre Sitzung endet in: ${sessionRemaingTime.minutes} : ${sessionRemaingTime.seconds}`}
        />
      )} */}
      <ToastMessage />
      <ProgressSpinnerCustom />
      <header>
        <Topbar />
      </header>
      <main>
        <Main token={token} user={user} resetIdleTimer={reset} />
      </main>
    </div>
  )
}

App.propTypes = {
  auth: PropTypes.object.isRequired,
  authActions: PropTypes.object.isRequired,
  getAllTags: PropTypes.func.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  candidatesActions: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.authReducer
})

const mapDispatchToProps = (dispatch) => ({
  authActions: bindActionCreators(authActions, dispatch),
  getAllTags: (token) => dispatch(tagActions.getAllTags(token)),
  getAllUsers: (token) => dispatch(userActions.getAllUsers(token)),
  getAllUsersList: (token, page, search, limit, active) =>
    dispatch(userActions.getAllUsersList(token, page, search, limit, active)),
  getAllCandidatesIdentitiesList: (token, page, search, limit, active) =>
    dispatch(
      candidatesActions.getAllCandidatesIdentitiesList(
        token,
        page,
        search,
        limit,
        active
      )
    ),
  getAllUsersIdentitiesList: (token, page, search, limit, active) =>
    dispatch(
      userActions.getAllUsersIdentitiesList(token, page, search, limit, active)
    ),
  getCurrentLogTime: (token) => dispatch(userActions.getCurrentTimeLog(token)),
  candidatesActions: bindActionCreators(candidatesActions, dispatch),
  companyActions: bindActionCreators(companyActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
