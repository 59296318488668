import { Creators as messages } from './statusMessages'
import { Creators as auth } from './auth'

export const Types = {
  MANAGER_REGISTER_REQUEST: 'managers/MANAGER_REGISTER_REQUEST',
  MANAGER_REGISTER_SUCCESS: 'managers/MANAGER_REGISTER_SUCCESS',
  MANAGER_REGISTER_FAIL: 'managers/MANAGER_REGISTER_FAIL',
  MANAGER_UPDATE_REQUEST: 'managers/MANAGER_UPDATE_REQUEST',
  MANAGER_UPDATE_SUCCESS: 'managers/MANAGER_UPDATE_SUCCESS',
  MANAGER_UPDATE_FAIL: 'managers/MANAGER_UPDATE_FAIL',
  MANAGER_DELETE_REQUEST: 'managers/MANAGER_DELETE_REQUEST',
  MANAGER_DELETE_SUCCESS: 'managers/MANAGER_DELETE_SUCCESS',
  MANAGER_DELETE_FAIL: 'managers/MANAGER_DELETE_FAIL',
  MANAGER_LOADING_REQUEST: 'managers/MANAGER_LOADING_REQUEST',
  MANAGER_LOADING_SUCCESS: 'managers/MANAGER_LOADING_SUCCESS',
  MANAGER_LOADING_FAIL: 'managers/MANAGER_LOADING_FAIL'
}

// Reducer
const INITIAL_STATE = {
  isLoading: false,
  managers: []
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.MANAGER_REGISTER_REQUEST:
    case Types.MANAGER_UPDATE_REQUEST:
    case Types.MANAGER_DELETE_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case Types.MANAGER_LOADING_REQUEST:
      return {
        ...state,
        managers: []
      }

    case Types.MANAGER_REGISTER_SUCCESS:
      return {
        ...state,
        managers: [action.payload.data, ...state.managers],
        isLoading: false
      }

    case Types.MANAGER_LOADING_SUCCESS:
      return { ...state, managers: action.payload, isLoading: false }

    case Types.MANAGER_UPDATE_SUCCESS:
      return {
        ...state,
        managers: state.managers.map((manager) =>
          manager._id === action.payload.id
            ? { ...manager, ...action.payload.manager }
            : manager
        ),
        isLoading: false
      }

    case Types.MANAGER_DELETE_SUCCESS:
      return {
        ...state,
        managers: state.managers.filter(
          (manager) => manager._id !== action.payload.id
        ),
        isLoading: false
      }

    case Types.MANAGER_REGISTER_FAIL:
    case Types.MANAGER_LOADING_FAIL:
    case Types.MANAGER_UPDATE_FAIL:
    case Types.MANAGER_DELETE_FAIL:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}

export const Creators = {
  addManager: (companyID, newManager, token) => {
    return async (dispatch) => {
      dispatch({ type: Types.MANAGER_REGISTER_REQUEST })
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/company/manager/${companyID}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify(newManager)
          }
        )
        const result = await response.json()
        if (response.ok) {
          if (newManager.photo) {
            const photo = newManager.photo.data
            newManager.photo = photo
          }
          dispatch({
            type: Types.MANAGER_REGISTER_SUCCESS,
            payload: {
              data: {
                ...newManager,
                company_id: companyID,
                ...{ _id: result.id }
              }
            }
          })
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else if (response.status === 401) {
          dispatch({ type: Types.USERS_LOADING_FAIL })
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else {
          dispatch({ type: Types.MANAGER_REGISTER_FAIL })
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        }
        return { result: response.ok, managers: result.managers }
      } catch (error) {
        dispatch({ type: Types.MANAGER_REGISTER_FAIL })
        dispatch(messages.defaultNetworkError())
      }
    }
  },

  getManagers: (searchCriteria, token, cid = '') => {
    return async (dispatch) => {
      dispatch({ type: Types.MANAGER_LOADING_REQUEST })
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/company/manager?cid=${cid}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.MANAGER_LOADING_SUCCESS,
            payload: result.result
          })
        } else if (response.status === 401) {
          dispatch({ type: Types.MANAGER_LOADING_FAIL })
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else {
          dispatch({ type: Types.MANAGER_LOADING_FAIL })
        }
      } catch (error) {
        dispatch({ type: Types.MANAGER_LOADING_FAIL })
        dispatch(messages.defaultNetworkError())
      }
    }
  },

  updateManager: (manager, id, token) => {
    return async (dispatch) => {
      // dispatch({ type: Types.MANAGER_UPDATE_REQUEST })
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/company/manager/${id}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify(manager)
          }
        )
        const result = await response.json()
        if (response.ok) {
          if (manager.photo) {
            const photo = manager.photo.data
            manager.photo = photo
          }
          dispatch({
            type: Types.MANAGER_UPDATE_SUCCESS,
            payload: { manager, id }
          })
        } else if (response.status === 401) {
          dispatch({ type: Types.MANAGER_UPDATE_FAIL })
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else {
          dispatch({ type: Types.MANAGER_UPDATE_FAIL })
        }
        return true
      } catch (error) {
        dispatch({ type: Types.MANAGER_UPDATE_FAIL })
        dispatch(messages.defaultNetworkError())
      }
    }
  },

  deleteManager: (id, token) => {
    return async (dispatch) => {
      dispatch({ type: Types.MANAGER_DELETE_REQUEST })
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/company/manager/${id}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({ type: Types.MANAGER_DELETE_SUCCESS, payload: { id } })
        } else if (response.status === 401) {
          dispatch({ type: Types.MANAGER_DELETE_FAIL })
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else {
          dispatch({ type: Types.MANAGER_DELETE_FAIL })
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        }
        return { result: response.ok, managers: result.managers }
      } catch (error) {
        dispatch({ type: Types.MANAGER_DELETE_FAIL })
        dispatch(messages.defaultNetworkError())
      }
    }
  }
}
