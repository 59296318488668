// Action types
const Types = {
  OPEN_COMPANY_PANEL: 'slideDownPanel/OPEN_COMPANY_PANEL',
  OPEN_CANDIDATE_PANEL: 'slideDownPanel/OPEN_CANDIDATEPANEL',
  RESET_SLIDE_DOWN: 'slideDownPanel/RESET_SLIDE_DOWN'
}

// Reducer
const INITIAL_STATE = {
  companyID: null,
  candidateID: null
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.OPEN_COMPANY_PANEL:
      return { ...state, companyID: action.payload }

    case Types.OPEN_CANDIDATE_PANEL:
      return { ...state, candidateID: action.payload }

    case Types.RESET_SLIDE_DOWN:
      return {
        companyID: null,
        candidateID: null
      }
    default:
      return state
  }
}

// Actions
export const Creators = {
  openCompanySlideDown: (id) => {
    return (dispatch) => {
      dispatch({ type: Types.OPEN_COMPANY_PANEL, payload: id })
    }
  },
  openCandidateSlideDown: (id) => {
    return (dispatch) => {
      dispatch({ type: Types.OPEN_CANDIDATE_PANEL, payload: id })
    }
  },
  resetSlideDown: () => {
    return (dispatch) => {
      dispatch({ type: Types.RESET_SLIDE_DOWN })
    }
  }
}
