/* eslint-disable */
import axios from 'axios'
import { getToken } from '../helpers/jwt'

const http = axios.create({
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    'Access-Control-Allow-Origin': '*'
  }
})

http.interceptors.request.use(
  (config) => {
    const tokens = getToken()
    if (tokens) {
      config.headers['Authorization'] = tokens
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

http.interceptors.response.use(
  (config) => {
    debugger
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default {
  get: http.get,
  post: http.post,
  put: http.put,
  delete: http.delete
}
