const stages = [
  {
    id: '-1',
    name: 'Recommend'
  },
  {
    id: '0',
    name: 'Sent'
  },
  {
    id: '1',
    name: 'I.'
  },
  {
    id: '2',
    name: 'II.'
  },
  {
    id: '3',
    name: 'III.'
  },
  {
    id: '4',
    name: 'Deal'
  },
  {
    id: '-2',
    name: 'Rejected'
  }
]

module.exports = { stages }
