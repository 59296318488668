/* eslint-disable */
import _ from 'lodash'

import { Creators as messages } from './statusMessages'
import { Creators as auth } from './auth'

export const Types = {
  COMPANY_REGISTER_REQUEST: 'companies/COMPANY_REGISTER_REQUEST',
  COMPANY_REGISTER_SUCCESS: 'companies/COMPANY_REGISTER_SUCCESS',
  COMPANY_REGISTER_FAIL: 'companies/COMPANY_REGISTER_FAIL',
  COMPANY_UPDATE_REQUEST: 'companies/COMPANY_UPDATE_REQUEST',
  COMPANY_UPDATE_SUCCESS: 'companies/COMPANY_UPDATE_SUCCESS',
  COMPANY_UPDATE_FAIL: 'companies/COMPANY_UPDATE_FAIL',
  COMPANY_DELETE_REQUEST: 'companies/COMPANY_DELETE_REQUEST',
  COMPANY_DELETE_SUCCESS: 'companies/COMPANY_DELETE_SUCCESS',
  COMPANY_DELETE_FAIL: 'companies/COMPANY_DELETE_FAIL',
  COMPANY_LOADING_REQUEST: 'companies/COMPANY_LOADING_REQUEST',
  COMPANY_LOADING_SUCCESS: 'companies/COMPANY_LOADING_SUCCESS',
  COMPANY_LIST_LOADING_REQUEST: 'companies/COMPANY_LIST_LOADING_REQUEST',
  COMPANY_LIST_LOADING_SUCCESS: 'companies/COMPANY_LIST_LOADING_SUCCESS',
  COMPANY_LIST_LOADING_FAIL: 'companies/COMPANY_LIST_LOADING_FAIL',
  COMPANY_SENDOUT_LIST_LOADING_REQUEST:
    'companies/COMPANY_SENDOUT_LIST_LOADING_REQUEST',
  COMPANY_SENDOUT_LIST_LOADING_SUCCESS:
    'companies/COMPANY_SENDOUT_LIST_LOADING_SUCCESS',
  COMPANY_SENDOUT_LIST_LOADING_FAIL:
    'companies/COMPANY_SENDOUT_LIST_LOADING_FAIL',
  COMPANY_LOADING_FAIL: 'companies/COMPANY_LOADING_FAIL',
  COMPANY_LOADING_SUCCESS_ONE: 'companies/COMPANY_LOADING_SUCCESS_ONE',
  COMPANY_LOADING_REST_SUCCESS: 'companies/COMPANY_LOADING_REST_SUCCESS',
  COMPANY_UPDATE_SEARCH_STATE: 'companies/COMPANY_UPDATE_SEARCH_STATE',
  COMPANY_LOADING_SEARCH_SUCCESS: 'companies/COMPANY_LOADING_SEARCH_SUCCESS',
  COMPANY_LOADING_PAGINATED_SUCCESS:
    'companies/COMPANY_LOADING_PAGINATED_SUCCESS',
  COMPANY_GET_JOBS: 'companies/COMPANY_GET_JOBS',
  COMPANY_ADD_JOB: 'companies/COMPANY_ADD_JOB',
  COMPANY_EDIT_JOB: 'companies/COMPANY_EDIT_JOB',
  COMPANY_DELETE_JOB: 'companies/COMPANY_DELETE_JOB'
}

// Reducer
/*
"companies" :contains all the companies from database,
"companiesSearch" :contains all the company results of the search,
"allCompanies" :contains the companies fetched from database that are paginated in the backend
*/
const INITIAL_STATE = {
  isLoading: false,
  companies: [],
  showList: false,
  search: [],
  kunden: false,
  wishlist: false,
  page: 1,
  companiesList: {
    list: [],
    totalCount: 0
  },
  sendoutList: {
    list: [],
    totalCount: 0
  },
  companiesSearch: {},
  allCompanies: {},
  sendoutCompany: {}
}

export default function reducer(state = INITIAL_STATE, action) {
  let updatedCompanyIndex = null
  let updatedCompanyData = {}
  const allCompanies = state.companies
  switch (action.type) {
    case Types.COMPANY_UPDATE_SEARCH_STATE:
      return {
        ...state,
        ...action.payload.data
      }
    case Types.COMPANY_REGISTER_REQUEST:
    case Types.COMPANY_LOADING_REQUEST:
    case Types.COMPANY_UPDATE_REQUEST:
    case Types.COMPANY_DELETE_REQUEST:
    case Types.COMPANY_SENDOUT_LIST_LOADING_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case Types.COMPANY_LIST_LOADING_REQUEST:
      return {
        ...state,
        showList:
          action.payload.page == 1 && action.payload.performSearch
            ? false
            : true,
        kunden: action.payload.kunden,
        page: action.payload.page,
        search: action.payload.search,
        wishlist: action.payload.wishlist
      }
    case Types.COMPANY_REGISTER_SUCCESS: {
      state.companies.unshift(action.payload.data)
      state.companiesList.list.unshift(action.payload.data)
      return {
        ...state,
        companies: [...state.companies],
        companiesList: {
          list: [...state.companiesList.list],
          totalCount: state.companiesList.totalCount + 1
        },
        isLoading: false
      }
    }
    case Types.COMPANY_LIST_LOADING_SUCCESS:
      // list: (action.payload.page === 1) ? [...action.payload.data] : [...state.companiesList.list, ...action.payload.data],

      return {
        ...state,
        showList: true,
        companiesList: {
          list: [...action.payload.data],
          totalCount: action.payload.totalCount,
          page: action.payload.page
        },
        isLoading: false
      }
    case Types.COMPANY_SENDOUT_LIST_LOADING_SUCCESS:
      return {
        ...state,
        sendoutList: {
          list: [...action.payload.data],
          totalCount: action.payload.totalCount
        },
        isLoading: false
      }
    case Types.COMPANY_LOADING_SEARCH_SUCCESS:
      return {
        ...state,
        companiesSearch: action.payload,
        isLoading: false
      }

    case Types.COMPANY_LOADING_SUCCESS:
      return {
        ...state,
        companies: action.payload,
        isLoading: false,
        companiesSearch: {}
      }

    case Types.COMPANY_GET_JOBS: {
      const { result, id, page } = action.payload
      const list = JSON.parse(JSON.stringify(state.companiesList.list))
      const findCompany = list.find((item) => item._id === id)
      if (!findCompany) {
        if (state.sendoutCompany._id === id) {
          return {
            ...state,
            sendoutCompany: {
              ...state.sendoutCompany,
              jobs: result['data']['records'],
              jobsCount: result['data']['count'],
              jobPage: page
            }
          }
        }
      }
      const userIndex = list.indexOf(list.find((item) => item._id === id))
      if (userIndex > -1) {
        list[userIndex]['jobs'] = result['data']['records']
        list[userIndex]['jobsCount'] = result['data']['count']
        list[userIndex]['jobPage'] = page
        return {
          ...state,
          companiesList: {
            ...state.companiesList,
            list: [...list]
          }
        }
      }
    }
    case Types.COMPANY_ADD_JOB: {
      const list = _.cloneDeep(state.companiesList.list)
      const userIndex = list.indexOf(
        list.find((item) => item._id === action.payload.id)
      )
      if (userIndex > -1) {
        if (_.hasIn(list[userIndex], 'jobs')) {
          const findSalaIndex = list[userIndex]['jobs'].indexOf(
            list[userIndex]['jobs'].find(
              (item) => item._id === action.payload.result._id
            )
          )
          if (findSalaIndex > -1) {
            list[userIndex]['jobs'][findSalaIndex] = {
              ...action.payload.result,
              ...action.payload.originalData
            }
          } else {
            list[userIndex]['jobs'].unshift({
              ...action.payload.result,
              ...action.payload.originalData,
              file: action.payload.result.file
            })
          }
        } else {
          list[userIndex]['jobs'] = [
            {
              ...action.payload.result,
              ...action.payload.originalData,
              file: action.payload.result.file
            }
          ]
        }

        return {
          ...state,
          companiesList: {
            ...state.companiesList,
            list: [...list]
          }
        }
      }
    }
    case Types.COMPANY_EDIT_JOB: {
      const salaryToUpdate = action.payload.result
      const list = _.cloneDeep(state.companiesList.list)
      const userIndex = list.indexOf(
        list.find((item) => item._id === salaryToUpdate.companyId)
      )
      if (userIndex > -1) {
        const findSalaIndex = list[userIndex]['jobs'].indexOf(
          list[userIndex]['jobs'].find(
            (item) => item._id === salaryToUpdate._id
          )
        )
        if (findSalaIndex > -1) {
          list[userIndex]['jobs'][findSalaIndex] = {
            ...list[userIndex]['jobs'][findSalaIndex],
            ...action.payload.originalData,
            file: salaryToUpdate.file
          }
          return {
            ...state,
            isLoading: false,
            companiesList: {
              ...state.companiesList,
              list: [...list]
            }
          }
        }
      }
    }
    case Types.COMPANY_DELETE_JOB: {
      const salaryToUpdate = action.payload.result
      const list = _.cloneDeep(state.companiesList.list)
      const userIndex = list.indexOf(
        list.find((item) => item._id === salaryToUpdate.companyId)
      )
      if (userIndex > -1) {
        const findSalaIndex = list[userIndex]['jobs'].indexOf(
          list[userIndex]['jobs'].find(
            (item) => item._id === salaryToUpdate._id
          )
        )
        if (findSalaIndex > -1) {
          list[userIndex]['jobs'] = list[userIndex]['jobs'].filter(
            (j) => j._id !== salaryToUpdate._id
          )
          list[userIndex]['jobsCount'] -= 1
          return {
            ...state,
            companiesList: {
              ...state.companiesList,
              list: [...list]
            }
          }
        }
      }
    }
    case Types.COMPANY_LOADING_REST_SUCCESS:
      action.payload.map((company) => {
        if (state.companies.find((x) => x._id === company._id) === undefined) {
          allCompanies.push(company)
        }
        return company
      })
      return {
        ...state,
        companies: allCompanies,
        isLoading: false
      }

    case Types.COMPANY_LOADING_SUCCESS_ONE:
      const result = action.payload
      // const list = JSON.parse(JSON.stringify(state.companiesList.list));
      // const userIndex = list.indexOf(list.find(item => item._id === result._id));
      // if (userIndex == -1) {
      if (state.sendoutCompany._id === result._id) {
        return {
          ...state,
          sendoutCompany: {
            ...state.sendoutCompany,
            ...result
          },
          isLoading: false
        }
      }
      return {
        ...state,
        // companiesList: {
        //   ...state.companiesList,
        //   list: [
        //     ...list,
        //     result
        //   ],

        // },
        sendoutCompany: { ...result },
        isLoading: false
      }
    // }
    case Types.COMPANY_UPDATE_SUCCESS:
      return {
        ...state,
        companies: [
          ...state.companies.map((company) => {
            if (company._id === action.payload.id) {
              return {
                ...company,
                ...action.payload.company
              }
            }
            return company
          })
        ],
        companiesList: {
          ...state.companiesList,
          list: [
            ...state.companiesList.list.map((company) => {
              if (company._id === action.payload.id) {
                return {
                  ...company,
                  ...action.payload.company
                }
              }
              return company
            })
          ]
        },
        isLoading: false
      }
    // updatedCompanyIndex = state.companies.findIndex(
    //   (company) => company._id === `${action.payload.id}`
    // )
    // updatedCompanyData = {
    //   ...state.companies[updatedCompanyIndex],
    //   ...action.payload.company
    // }
    // allCompanies.splice(updatedCompanyIndex, 1)
    // allCompanies.unshift(updatedCompanyData)
    // return {
    //   ...state,
    //   companies: allCompanies,
    //   isLoading: false
    // }

    case Types.COMPANY_DELETE_SUCCESS:
      return {
        ...state,
        companies: [
          ...state.companies.filter(
            (company) => company._id !== action.payload.id
          )
        ],
        companiesList: {
          list: [
            ...state.companiesList.list.filter(
              (company) => company._id !== action.payload.id
            )
          ],
          totalCount: state.companiesList.totalCount - 1
        },
        isLoading: false
      }

    case Types.COMPANY_REGISTER_FAIL:
    case Types.COMPANY_LOADING_FAIL:
    case Types.COMPANY_UPDATE_FAIL:
    case Types.COMPANY_DELETE_FAIL:
      return {
        ...state,
        isLoading: false
      }

    default:
      return state
  }
}

export const Creators = {
  addCompany: (newCompany, token) => {
    return async (dispatch) => {
      dispatch({
        type: Types.COMPANY_REGISTER_REQUEST
      })
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/company`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify(newCompany)
          }
        )
        const result = await response.json()
        if (response.ok) {
          if (newCompany.logo) {
            const logo = newCompany.logo.data
            newCompany.logo = logo
          }
          dispatch({
            type: Types.COMPANY_REGISTER_SUCCESS,
            payload: {
              data: {
                ...newCompany,
                ...result.company,
                tags: newCompany.tagInOriginalFormat
              }
            }
          })
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else if (response.status === 401) {
          dispatch({
            type: Types.USERS_LOADING_FAIL
          })
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else {
          dispatch({
            type: Types.COMPANY_REGISTER_FAIL
          })
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        }
        return response.ok
      } catch (error) {
        dispatch({
          type: Types.COMPANY_REGISTER_FAIL
        })
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  addProtocol: (protocol, token, socket) => {
    return async (dispatch) => {
      dispatch({
        type: Types.COMPANY_REGISTER_REQUEST
      })
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/company/addProtocol`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify(protocol)
          }
        )
        const result = await response.json()
        if (socket) {
          socket.emit('createReminder')
        }
      } catch (error) {
        dispatch({
          type: Types.COMPANY_REGISTER_FAIL
        })
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  updateProtocol: (protocol, token) => {
    return async (dispatch) => {
      dispatch({
        type: Types.COMPANY_REGISTER_REQUEST
      })
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/company/updateProtocol`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify(protocol)
          }
        )
        const result = await response.json()

        return result
      } catch (error) {
        dispatch({
          type: Types.COMPANY_REGISTER_FAIL
        })
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  getCompanies: (token) => {
    return async (dispatch) => {
      dispatch({
        type: Types.COMPANY_LOADING_REQUEST
      })
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/company`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.COMPANY_LOADING_SUCCESS,
            payload: result.result
          })
        } else if (response.status === 401) {
          dispatch({
            type: Types.COMPANY_LOADING_FAIL
          })
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else {
          dispatch({
            type: Types.COMPANY_LOADING_FAIL
          })
        }
      } catch (error) {
        dispatch({
          type: Types.COMPANY_LOADING_FAIL
        })
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  // setSearchState: (data,performSearch,token) => {
  //   if(performSearch){

  //   }
  //   else return async (dispatch) => {
  //     dispatch({
  //       type: Types.COMPANY_UPDATE_SEARCH_STATE,
  //       payload: data
  //     })
  //   }
  // },

  getRestCompanies: (token) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/company/rest`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.COMPANY_LOADING_REST_SUCCESS,
            payload: result.result
          })
        } else if (response.status === 401) {
          dispatch({
            type: Types.COMPANY_LOADING_FAIL
          })
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else {
          dispatch({
            type: Types.COMPANY_LOADING_FAIL
          })
        }
      } catch (error) {
        dispatch({
          type: Types.COMPANY_LOADING_FAIL
        })
        dispatch(messages.defaultNetworkError())
      }
    }
  },

  getCompany: (id, token, populate = false) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/company/one/${id}?populate=${populate}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.COMPANY_LOADING_SUCCESS_ONE,
            payload: result.result
          })
          return result.result
        } else if (response.status === 401) {
          dispatch({
            type: Types.COMPANY_LOADING_FAIL
          })
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else {
          dispatch({
            type: Types.COMPANY_LOADING_FAIL
          })
        }
      } catch (error) {
        dispatch({
          type: Types.COMPANY_LOADING_FAIL
        })
        dispatch(messages.defaultNetworkError())
      }
    }
  },

  updateCompany: (company, id, token, showMessage = false) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/company/${id}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify(company)
          }
        )
        const result = await response.json()

        if (response.ok) {
          if (company.logo) {
            const logo = company.logo.data
            company.logo = logo
          }
          dispatch({
            type: Types.COMPANY_UPDATE_SUCCESS,
            payload: {
              company,
              id
            }
          })

          if (showMessage) {
            dispatch(
              messages.setMessage({
                status: response.status,
                message: result.message
              })
            )
          }
        } else if (response.status === 401) {
          dispatch({
            type: Types.COMPANY_UPDATE_FAIL
          })
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else {
          dispatch({
            type: Types.COMPANY_UPDATE_FAIL
          })
        }
        return result
      } catch (error) {
        dispatch({
          type: Types.COMPANY_UPDATE_FAIL
        })
        dispatch(messages.defaultNetworkError())
      }
    }
  },

  deleteCompany: (id, token) => {
    return async (dispatch) => {
      dispatch({
        type: Types.COMPANY_DELETE_REQUEST
      })
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/company/${id}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.COMPANY_DELETE_SUCCESS,
            payload: { id }
          })
        } else if (response.status === 401) {
          dispatch({
            type: Types.COMPANY_DELETE_FAIL
          })
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else {
          dispatch({
            type: Types.COMPANY_DELETE_FAIL
          })
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        }
        return response.ok
      } catch (error) {
        dispatch({
          type: Types.COMPANY_DELETE_FAIL
        })
        dispatch(messages.defaultNetworkError())
      }
    }
  },

  updateCompanyManagers: (company, id) => {
    return (dispatch) => {
      dispatch({
        type: Types.COMPANY_UPDATE_SUCCESS,
        payload: { company, id }
      })
    }
  },

  searchCompanies: (searchResults) => {
    return async (dispatch) => {
      dispatch({
        type: Types.COMPANY_LOADING_SEARCH_SUCCESS,
        payload: searchResults
      })
    }
  },
  getCompaniesList: (
    token,
    page = 1,
    search = '',
    kunden = false,
    performSearch,
    wishlist = false
  ) => {
    return async (dispatch) => {
      dispatch({
        type: Types.COMPANY_LIST_LOADING_REQUEST,
        payload: { page, search, kunden, performSearch, wishlist }
      })
      if (!performSearch) return
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/company/getCompaniesList/`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify({
              search,
              page,
              kunden,
              wishlist
            })
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.COMPANY_LIST_LOADING_SUCCESS,
            payload: result.result
          })
        } else if (response.status === 401) {
          dispatch({
            type: Types.COMPANY_LIST_LOADING_FAIL
          })
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else {
          dispatch({
            type: Types.COMPANY_LIST_LOADING_FAIL
          })
        }
      } catch (error) {
        console.log(error)
        dispatch({
          type: Types.COMPANY_LIST_LOADING_FAIL
        })
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  getCompanySendoutMails: (token, page = 1, search = '', kunden = false) => {
    return async (dispatch) => {
      dispatch({
        type: Types.COMPANY_SENDOUT_LIST_LOADING_REQUEST
      })
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/company/getCompanySendoutMails`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify({
              search,
              limit: 500,
              page,
              kunden,
              options: {
                name: 1,
                email: 1,
                active_client: 1
              }
            })
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.COMPANY_SENDOUT_LIST_LOADING_SUCCESS,
            payload: result.result
          })
        } else if (response.status === 401) {
          dispatch({
            type: Types.COMPANY_SENDOUT_LIST_LOADING_FAIL
          })
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else {
          dispatch({
            type: Types.COMPANY_SENDOUT_LIST_LOADING_FAIL
          })
        }
      } catch (error) {
        console.log(error)
        dispatch({
          type: Types.COMPANY_SENDOUT_LIST_LOADING_FAIL
        })
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  getCompanyJobs: (id, token, page, limit) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/job/getJobsList/${id}?page=${page}&limit=${limit}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.COMPANY_GET_JOBS,
            payload: { result, id, page }
          })
        } else if (response.status === 401) {
          dispatch(auth.logout(token))
        } else {
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
          throw new Error('throw')
        }
        // dispatch(
        //   messages.setMessage({
        //     status: response.status,
        //     message: result.message
        //   })
        // )
        return response.ok
      } catch (error) {
        if (error && error.message === 'throw') {
          return
        }
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  addCompanyJob: (job, editObject, id, token) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/job/addCompanyJob/${id}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify(job)
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.COMPANY_ADD_JOB,
            payload: {
              result: result.data,
              id,
              originalData: _.isEmpty(editObject) ? job : editObject
            }
          })
        } else if (response.status === 401) {
          dispatch(auth.logout(token))
        } else {
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
          throw new Error('throw')
        }
        dispatch(
          messages.setMessage({
            status: response.status,
            message: result.message
          })
        )
        return response.ok
      } catch (error) {
        if (error && error.message === 'throw') {
          return
        }
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  editCompanyJob: (job, editObject, id, token, showToast = false) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/job/updateJob/${id}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify(job)
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.COMPANY_EDIT_JOB,
            payload: {
              result: result.data,
              id,
              originalData: _.isEmpty(editObject) ? job : editObject
            }
          })
        } else if (response.status === 401) {
          dispatch(auth.logout(token))
        } else {
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
          throw new Error('throw')
        }

        if (showToast) {
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        }
        return response.ok
      } catch (error) {
        if (error && error.message === 'throw') {
          throw new Error('throw')
        }
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  deleteCompanyJob: (job, token) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/job/deleteJob/${job._id}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.COMPANY_DELETE_JOB,
            payload: { result: result.data, job }
          })
        } else if (response.status === 401) {
          dispatch(auth.logout(token))
        } else {
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
          throw new Error('throw')
        }
        dispatch(
          messages.setMessage({
            status: response.status,
            message: result.message
          })
        )
        return response.ok
      } catch (error) {
        if (error && error.message === 'throw') {
          throw new Error('throw')
        }
        dispatch(messages.defaultNetworkError())
      }
    }
  }
}
