/* eslint-disable */
import axios from '../../intercepters/axios'
import { Creators as messages } from './statusMessages'
import { Creators as auth } from './auth'

export const Types = {
  GET_DEALS: 'profitCenter/GET_DEALS',
  CREATE_DEAL: 'profitCenter/CREATE_DEAL',
  UPDATE_DEAL: 'profitCenter/UPDATE_DEAL',
  DELETE_DEAL: 'profitCenter/DELETE_DEAL',
  INIT_DEAL: 'profitCenter/INIT_DEAL',
  RESET_DEAL: 'profitCenter/RESET_DEAL'
}

// Reducer
const INITIAL_STATE = {
  initialDeal: {
    company: {},
    candidate: {},
    job: {}
  },
  deals: {
    list: [],
    totalCount: 0
  },
  deal: {}
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.INIT_DEAL: {
      state.initialDeal = {
        ...action.payload
      }
      return { ...state }
    }
    case Types.RESET_DEAL: {
      state.initialDeal = {
        company: {},
        candidate: {},
        job: {}
      }
      return { ...state }
    }
    case Types.GET_DEALS: {
      const { count, records } = action.payload
      return {
        ...state,
        deals: {
          ...state.deals,
          list: [...records],
          totalCount: count
        }
      }
    }
    case Types.CREATE_DEAL: {
      const { result, deal } = action.payload
      const newList = JSON.parse(JSON.stringify(state.deals.list))
      const findDeal = newList.find((ca) => ca._id === result._id)
      if (findDeal) {
        const cIdx = newList.indexOf(findDeal)
        if (cIdx > -1) {
          newList[cIdx] = { ...result.deal, _id: result._id }
          return {
            ...state,
            deals: {
              ...state.deals,
              list: [...newList]
            }
          }
        }
      }

      newList.push({
        ...result,
        candidate: {
          ...result.candidate,
          ownerName: deal.candidateOwnerName,
          name: deal.candidateName,
          photo: deal.candidatePhoto
        },
        job: {
          ...result.job,
          ownerName: deal.jobOwnerName
        },
        companyPhoto: deal.companyPhoto,
        companyName: deal.companyName
      })
      return {
        ...state,
        deals: {
          ...state.deals,
          list: [...newList]
        }
      }
    }
    case Types.UPDATE_DEAL: {
      const { result, deal } = action.payload
      const newList = JSON.parse(JSON.stringify(state.deals.list))
      const findDeal = newList.find((ca) => ca._id === result._id)
      if (findDeal) {
        const cIdx = newList.indexOf(findDeal)
        if (cIdx > -1) {
          newList[cIdx] = {
            ...result,
            candidate: {
              ...result.candidate,
              name: deal.candidateName,
              ownerName: deal.candidateOwnerName,
              photo: deal.candidatePhoto
            },
            job: {
              ...result.job,
              ownerName: deal.jobOwnerName
            },
            companyPhoto: deal.companyPhoto,
            companyName: deal.companyName
          }
          return {
            ...state,
            deals: {
              ...state.deals,
              list: [...newList]
            }
          }
        }
      }
      break
    }
    case Types.DELETE_DEAL: {
      const { id } = action.payload
      const newList = JSON.parse(JSON.stringify(state.deals.list))
      const findDeal = newList.find((ca) => ca._id === id)

      if (findDeal) {
        const cIdx = newList.indexOf(findDeal)
        if (cIdx > -1) {
          newList.splice(cIdx, 1)
          return {
            ...state,
            deals: {
              ...state.deals,
              list: [...newList]
            }
          }
        }
      }
    }
    default:
      return state
  }
}

export const Creators = {
  initializeDeal: (company = {}, candidate = {}, job = {}) => {
    return async (dispatch) => {
      dispatch({
        type: Types.INIT_DEAL,
        payload: {
          company,
          candidate,
          job
        }
      })
    }
  },
  resetDeal: () => {
    return async (dispatch) => {
      dispatch({
        type: Types.RESET_DEAL
      })
    }
  },
  addDeal: (deal, token) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/deal/addDeal`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify(deal)
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.CREATE_DEAL,
            payload: {
              result: result.data,
              deal: deal
            }
          })
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else if (response.status === 401) {
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
          throw Error(result.message)
        } else {
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
          throw Error(result.message)
        }
        return result
      } catch (error) {
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  updateDeal: (dealId, deal, token) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/deal/updateDeal/${dealId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify(deal)
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.UPDATE_DEAL,
            payload: {
              result: result.data,
              deal: deal
            }
          })
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else if (response.status === 401) {
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
          throw Error(result.message)
        } else {
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
          throw Error(result.message)
        }
        return result
      } catch (error) {
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  getDeals: (page = 1, limit = 10, token, companyId) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          companyId
            ? `${process.env.REACT_APP_SERVER_API}/deal/getCompanyDeals/${companyId}?page=${page}&limit=${limit}`
            : `${process.env.REACT_APP_SERVER_API}/deal/getDeals?page=${page}&limit=${limit}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.GET_DEALS,
            payload: result.data
          })
        } else if (response.status === 401) {
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        }
      } catch (error) {
        dispatch(messages.defaultNetworkError())
      }
    }
  },
  deleteDeal: (id, token) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/deal/${id}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.DELETE_DEAL,
            payload: { id }
          })

          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else if (response.status === 401) {
          dispatch(auth.logout(token))
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        } else {
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        }
        return response.ok
      } catch (error) {
        dispatch(messages.defaultNetworkError())
      }
    }
  }
}
