import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import PropTypes from 'prop-types'
import Login from '../pages/Login/Login'
import InfoUser from '../components/InfoUser/InfoUser'
import ProgressSpinner from '../components/Spinner/Spinner'
const Companies = lazy(() => import('../pages/Companies/CompaniesList'))
const CompaniesDetail = lazy(() =>
  import('../pages/Candidates/CandidatesPanel/CompaniesDetail')
)
const CandidatesDetail = lazy(() =>
  import('../pages/Candidates/CandidatesDetail')
)
const Candidates = lazy(() => import('../pages/Candidates/CandidatesList'))
const CorpUser = lazy(() => import('../pages/Admin/CorpUser'))
const CorpTag = lazy(() => import('../pages/Admin/CorpTag'))
const VerifizierenCandidate = lazy(() =>
  import('../pages/Candidates/verifyCandidate')
)
const ProfitCenter = lazy(() =>
  import('../pages/Admin/profitCenter/profitCenter')
)

function Main({ token, user, resetIdleTimer }) {
  let routes

  if (token) {
    routes = (
      <Switch>
        <Route path="/admin/tags" component={CorpTag} />
        {user.admin && <Route path="/admin/mitarbeiten" component={CorpUser} />}
        <Route path="/unternehmen" component={Companies} />
        <Route exact path="/kandidaten" component={Candidates} />
        <Route exact path="/profitcenter" component={ProfitCenter} />
        <Route
          exact
          path="/kandidaten/verifizieren"
          component={VerifizierenCandidate}
        />
        <Route path="/:_id" component={CandidatesDetail} />
        <Route path="/CompaniesDetail/:_id" component={CompaniesDetail} />
        <Route path="/" component={InfoUser} />
      </Switch>
    )
  } else {
    routes = (
      <Switch>
        <Route
          exact
          path="/kandidaten/verifizieren"
          component={VerifizierenCandidate}
        />
        <Route
          path="/"
          render={(props) => (
            <Login resetIdleTimer={resetIdleTimer} {...props} />
          )}
        />
      </Switch>
    )
  }
  return (
    <div>
      <Suspense fallback={<ProgressSpinner show={true} />}>{routes}</Suspense>
    </div>
  )
}

Main.propTypes = {
  token: PropTypes.string,
  user: PropTypes.object
}

export default Main
