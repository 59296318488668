import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import MenuBar from '../Menubar/Menubar'
import MenubarSmall from '../Menubar/MenubarSmall'
import Tabmenu from '../Tabmenu/Tabmenu'
import { useHistory } from 'react-router-dom'
import { useSubdomain } from '../../hooks/useSubdomain'

const tenantLogos = {
  database: 'active-logo.png',
  crm: 'asx-logo.png',
  green: 'green.jpeg'
}

function Topbar({ auth }) {
  const { token } = auth
  const history = useHistory()
  const { tenantName } = useSubdomain()

  return (
    <div id="topbar" className="p-container p-flex-sm-row">
      <div
        className="logo p-col-fixed p-d-flex p-jc-center p-md-12"
        style={{ width: '200px' }}
      >
        <img
          className={`${token ? 'p-as-center' : 'p-as-center mb-40'}`}
          src={`/assets/images/${tenantLogos[tenantName] || 'active-logo.png'}`}
          alt="logo"
          onClick={() => {
            history.push('/')
          }}
        />
      </div>
      <div id="topbar-menubar">
        <div className="nav-larg">{token && <MenuBar />}</div>
        <div className="nav-small">{token && <MenubarSmall />}</div>
      </div>
      <div id="topbar-tabmenu">{token && <Tabmenu />}</div>
    </div>
  )
}

Topbar.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.authReducer
})

export default connect(mapStateToProps, null)(Topbar)
