import React, { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import Cropper from 'react-easy-crop'
import getCroppedImg from '../../helpers/cropImage'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { Slider } from '@material-ui/core'

export default function PhotoCropper({ show = false, onChange, onHide }) {
  const initialRender = useRef(true)
  const fileUploaderRef = useRef()
  const [isVisible, setIsVisible] = useState(false)
  const [imgPreview, setImgPreview] = useState(null)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState({})
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)

  useEffect(() => {
    if (!isVisible && !initialRender.current) {
      onHide()
    }
  }, [isVisible])

  useEffect(() => {
    if (!initialRender.current) {
      onChange(croppedImage)
    }
  }, [croppedImage.data])

  useEffect(() => {
    if (show) {
      setIsVisible(true)
      initialRender.current = false
    }
    return () => {
      setImgPreview(null)
      setZoom(1)
    }
  }, [show])

  const pickFile = (event) => {
    if (event.target.files && event.target.files.length === 1) {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(event.target.files[0])
      fileReader.onload = () => {
        setImgPreview(fileReader.result)
      }
      setCroppedImage((prev) => ({
        ...prev,
        filename: event.target.files[0].name
      }))
    }
  }

  const createCroppedImg = async () => {
    if (imgPreview && croppedAreaPixels) {
      const croppedImg = await getCroppedImg(imgPreview, croppedAreaPixels)
      const resizedImg = await resizedataURL(croppedImg, 300, 300)
      setCroppedImage((prev) => ({
        ...prev,
        data: resizedImg
      }))
    } else {
      setCroppedImage({ data: '', filename: '' })
    }
    setIsVisible(false)
  }

  const resizedataURL = (image, wantedWidth, wantedHeight) => {
    return new Promise(function (resolve, reject) {
      const img = document.createElement('img')

      img.onload = function () {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        canvas.width = wantedWidth
        canvas.height = wantedHeight

        ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight)

        const dataURI = canvas.toDataURL()

        resolve(dataURI)
      }
      img.src = image
    })
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const footer = (
    <div className="p-d-flex p-jc-end p-mt-2">
      <Button
        className="p-button-outlined p-button-info"
        type="button"
        label="Wählen"
        icon="pi pi-folder-open"
        onClick={() => {
          fileUploaderRef.current.click()
        }}
      />
      <Button
        className="p-button-outlined p-button-info"
        type="button"
        label="Speichern"
        icon="pi pi-save"
        onClick={createCroppedImg}
      />
    </div>
  )

  return (
    <div className="photo-uploader">
      <Dialog
        header="Foto auswählen"
        visible={isVisible}
        footer={footer}
        onHide={() => setIsVisible(false)}
      >
        <div>
          <div className="photo-upload-preview">
            <div className="crop-container">
              <Cropper
                image={imgPreview}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                showGrid={true}
                restrictPosition={false}
              />
            </div>
            {imgPreview && (
              <Slider
                aria-labelledby="Zoom"
                value={zoom}
                min={0.1}
                max={3}
                step={0.01}
                onChange={(e, zoom) => setZoom(zoom)}
              />
            )}
          </div>
          <input
            ref={fileUploaderRef}
            type="file"
            accept=".jpg,.jpeg,.png"
            style={{ display: 'none' }}
            onChange={pickFile}
          />
        </div>
      </Dialog>
    </div>
  )
}

PhotoCropper.propTypes = {
  show: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired
}
