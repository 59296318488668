/* eslint-disable */
import { Creators as messages } from './statusMessages'
import UIfx from 'uifx'
import bellAudio from './notification-sound.wav'
import { useHistory } from 'react-router-dom'
import { getToken, getTokenExpirationDate, getUser } from '../../helpers/jwt'
// Store : Hold all the data that will be shared in the app.
// Actions: it describes what you wanna do, the definition of the action that will be taking
// Reducer : is going to check which action we did, and based on it going to transform our state, and do some modification

export const Types = {
  LOGIN_SUCCESS: 'auth/LOGIN_SUCCESS',
  LOGOUT_SUCCESS: 'auth/LOGOUT_SUCCESS',
  LOGIN_FAIL: 'auth/LOGIN_FAIL',
  UPDATE_TIMEOUT: 'auth/UPDATE_TIMEOUT',
  LOGOUT_FAIL: 'auth/LOGOUT_FAIL',
  USER_LOADING: 'auth/USER_LOADING',
  USER_LOADED: 'auth/USER_LOADED',
  SERVER_UNAVAILABLE: 'auth/SERVER_UNAVAILABLE',
  USER_NOTIFICATION: 'auth/USER_NOTIFICATION',
  USER_SET_SOCKET: 'auth/USER_SET_SOCKET',
  USER_NOTIFICATION_IS_READ: 'auth/USER_NOTIFICATION_IS_READ',
  USER_NOTIFICATION_SHOW_BELL: 'auth/USER_NOTIFICATION_SHOW_BELL',
  USER_NOTIFICATION_HIDE_BELL: 'auth/USER_NOTIFICATION_HIDE_BELL',
  USER_NOTIFICATION_TURN_OFF: 'auth/USER_NOTIFICATION_TURN_OFF'
}

// Reducer
const INITIAL_STATE = {
  isLoading: false,
  token: null,
  createdAt: null,
  expiresIn: null,
  socket: null,
  user: null,
  notificationId: null,
  updateReminder: undefined,
  userNotification: [],
  timeoutIntervalId: null,
  showBellNotification: false
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.USER_LOADING:
      return { ...state, isLoading: true }

    case Types.USER_LOADED:
      return { ...state, isLoading: false }
    case Types.USER_NOTIFICATION_SHOW_BELL:
      return { ...state, showBellNotification: true }
    case Types.USER_NOTIFICATION_HIDE_BELL:
      return { ...state, showBellNotification: false }
    case Types.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        token: action.payload.token,
        createdAt: action.payload.createdAt,
        expiresIn: action.payload.expiresIn,
        user: action.payload.user
      }
    case Types.USER_NOTIFICATION:
      return {
        ...state,
        userNotification: action.payload
      }
    case Types.USER_NOTIFICATION_IS_READ:
      return {
        ...state,
        notificationId: action.payload
      }
    case Types.USER_NOTIFICATION_TURN_OFF:
      return {
        ...state,
        updateReminder: Math.random()
      }
    case Types.USER_SET_SOCKET:
      return {
        ...state,
        socket: action.payload
      }
    case Types.UPDATE_TIMEOUT:
      return {
        ...state,
        timeoutIntervalId: action.payload
      }
    case Types.LOGOUT_SUCCESS:
    case Types.LOGOUT_FAIL:
    case Types.LOGIN_FAIL:
    case Types.SERVER_UNAVAILABLE:
      if (state.timeoutIntervalId) {
        clearInterval(state.timeoutIntervalId)
      }
      return {
        ...state,
        isLoading: false,
        token: null,
        createdAt: null,
        expiresIn: null,
        user: null
      }

    default:
      return state
  }
}

export const Creators = {
  createNotification: (token) => {
    return async (dispatch, getState) => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_API}/notification/getNotifications`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token
          }
        }
      )
      const result = await response.json()
      if (response.ok) {
        dispatch({
          type: Types.USER_NOTIFICATION,
          payload: result.result.data
        })
      }
    }
  },
  notificationGetEmit: (event) => {
    return async (dispatch, getState) => {
      switch (event) {
        case 'show_bell':
          const bell = new UIfx(bellAudio, {
            volume: 0.4, // number between 0.0 ~ 1.0
            throttleMs: 100
          })
          bell.play()
          dispatch({
            type: Types.USER_NOTIFICATION_SHOW_BELL,
            payload: true
          })
          dispatch({
            type: 'messages/SET_MESSAGE',
            payload: {
              status: -100,
              message: 'You have recieved a notification'
            }
          })
          break
        case 'hide_bell':
          dispatch({
            type: Types.USER_NOTIFICATION_HIDE_BELL,
            payload: true
          })
          break
        default:
          break
      }
    }
  },
  turnOffNotification: (token, id) => {
    return async (dispatch, getState) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/notification/turnOffNotification/${id}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        const result = await response.json()
        if (response.ok) {
          dispatch({
            type: Types.USER_NOTIFICATION_TURN_OFF,
            payload: id
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
  notificationIsRead: (token, id) => {
    return async (dispatch, getState) => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_API}/notification/notificationIsRead/${id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token
          }
        }
      )
      const result = await response.json()
      if (response.ok) {
        dispatch({
          type: Types.USER_NOTIFICATION_IS_READ,
          payload: id
        })
      }
    }
  },
  setSocket: (socket) => {
    return async (dispatch, getState) => {
      if (socket) {
        dispatch({
          type: Types.USER_SET_SOCKET,
          payload: socket
        })
      }
    }
  },
  login: (credencials) => {
    return async (dispatch, getState) => {
      dispatch({ type: Types.USER_LOADING })
      let timeZone = new Date().getTimezoneOffset()
      const timeZoneStr = Intl.DateTimeFormat().resolvedOptions().timeZone

      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/auth/login?timezone=${timeZone}&timeZoneStr=${timeZoneStr}`,

          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(credencials)
          }
        )

        const result = await response.json()
        if (response.ok) {
          const { user, token, createdAt, expiresIn } = result

          // Converts the starting session time to miliseconds and correct the timezone.
          const sessionStartingTime = new Date(createdAt)
          const sessionCorrectedTime = sessionStartingTime.setHours(
            sessionStartingTime.getHours() - 1
          )

          // I subtract 5 minutes to have a time window to show the remainig time or to avoid to login for 5 minutes.
          // const sessionTime = +expiresIn.match(/\d/g).join('') - 1
          const session = {
            user,
            token,
            createdAt: sessionCorrectedTime,
            expiresIn
          }
          // Stores the user and session information in the browser memory.
          localStorage.setItem('user_session', JSON.stringify(session))
          dispatch({
            type: Types.LOGIN_SUCCESS,
            payload: session
          })
          dispatch({ type: Types.USER_LOADED })
        } else {
          dispatch({
            type: Types.LOGIN_FAIL
          })
        }
        dispatch(
          messages.setMessage({
            status: response.status,
            message: result.message
          })
        )
      } catch (error) {
        dispatch({ type: Types.SERVER_UNAVAILABLE })
        dispatch(messages.defaultNetworkError('Login...'))
        throw new Error('Failed Login')
      }
    }
  },

  logout: (token) => {
    return async (dispatch) => {
      let timeZone = new Date().getTimezoneOffset()
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_API}/auth/logout?timezone=${timeZone}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          }
        )
        localStorage.clear()
        localStorage.removeItem('user_session')

        const result = await response.json()
        if (response.ok) {
          dispatch({ type: Types.LOGOUT_SUCCESS })
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
          window.history.replaceState(null, '', '/')
          window.location.href = '/'
        } else {
          dispatch({ type: Types.LOGOUT_FAIL })
          dispatch(
            messages.setMessage({
              status: response.status,
              message: result.message
            })
          )
        }
        // window.location.reload(false);
      } catch (error) {
        dispatch({ type: Types.SERVER_UNAVAILABLE })
        dispatch(messages.defaultNetworkError('Logout...'))
      }
    }
  },

  localLogout: () => {
    return (dispatch) => {
      localStorage.clear()
      localStorage.removeItem('user_session')
      dispatch({ type: Types.LOGOUT_SUCCESS })
      // window.location.reload();
    }
  },
  updateTimeoutId: (id) => {
    return (dispatch) => {
      dispatch({ type: Types.UPDATE_TIMEOUT, payload: id })
      // window.location.reload();
    }
  },
  // Gets the memorized session and check if it is still valid.
  sessionValidator: () => {
    return async (dispatch) => {
      const data = await JSON.parse(localStorage.getItem('user_session'))

      // Verify if exits a session expiration, if true exists a session.
      if (data) {
        // Calculates the session remaing time.
        const remaingTime = Date.now() - parseInt(data.createdAt)

        // It checks if the session has still remaining time to prevent calls to the server and creation of a new session.
        if (remaingTime < +data.expiresIn) {
          // Checks if the token is still valid in the server.
          try {
            const response = await fetch(
              `${process.env.REACT_APP_SERVER_API}/auth/check_token`,
              {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: data.token
                }
              }
            )
            if (response.ok) {
              dispatch({ type: Types.LOGIN_SUCCESS, payload: data })
            } else {
              dispatch({ type: Types.LOGOUT_SUCCESS })
            }
          } catch (error) {
            dispatch({ type: Types.SERVER_UNAVAILABLE })
            dispatch(messages.defaultNetworkError('Session Validator...'))
          }
        }
      }
    }
  },
  jwtInitializer: () => {
    return async (dispatch) => {
      const token = getToken()
      const user = getUser()

      if (token && user) {
        dispatch({
          type: Types.LOGIN_SUCCESS,
          payload: {
            user,
            token,
            createdAt: new Date(),
            expiresIn: getTokenExpirationDate(token)
          }
        })
      }
    }
  }
}
