import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { MegaMenu } from 'primereact/megamenu'
import { Creators as authActions } from '../../redux/ducks/auth'
import { Creators as addDataPanelActions } from '../../redux/ducks/addDataPanel'

function MenuBar({ authState, authLogout }) {
  const isAdmin = authState.user.admin
  const history = useHistory()
  const [items] = useState([
    {
      label: 'Menu',
      className: 'btn bars-icon',
      command: () => {
        const isMitarbeiterVisible = items[0].items[0][1].items[0]
        const isSchlagworteDisable = items[0].items[0][0].items[3]

        if (isAdmin === false) {
          isMitarbeiterVisible.className = 'show_no_mit'
          isSchlagworteDisable.className = 'disable_schlagworte'
        }
      },
      items: [
        [
          {
            label: 'Hauptmenu',
            items: [
              // {
              //   label: 'Dashboard',
              //   icon: 'pi pi-compass',
              //   command: () => {
              //     history.push('/')
              //   }
              // },
              {
                label: 'Profit Center',
                icon: 'pi pi-euro',
                command: () => {
                  history.push('/profitcenter')
                }
              },
              {
                label: 'Academy',
                className: 'categories',
                icon: 'pi pi-bookmark',
                command: () => {
                  window.open('https://vimeo.com/showcase/9100460', '_blank')
                }
              },
              {
                label: 'Schlagworte',
                className: '',
                icon: 'pi pi-fw pi-tags',
                command: () => {
                  history.push('/admin/tags')
                }
              }
            ]
          },
          {
            label: 'Einstellungen',
            items: [
              {
                label: 'Mitarbeiter',
                className: '',
                icon: 'pi pi-fw pi-users',
                command: () => {
                  history.push('/admin/mitarbeiten')
                }
              }
            ]
          },
          {
            label: 'Session',
            items: [
              {
                label: 'Logout',
                className: 'category-logout categories',
                icon: 'pi pi-fw pi-sign-out',
                command: () => {
                  authLogout(authState.token)
                }
              }
            ]
          }
        ]
      ]
    }
  ])

  return <MegaMenu id="topbar-menubar" model={items} orientation="horizontal" />
}

MenuBar.propTypes = {
  authState: PropTypes.object.isRequired,
  authLogout: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  authState: state.authReducer
})

const mapDispatchToProps = (dispatch) => ({
  authLogout: (token) => dispatch(authActions.logout(token)),
  addDataPanelOpen: (path) => dispatch(addDataPanelActions.openPanel(path))
})

export default connect(mapStateToProps, mapDispatchToProps)(MenuBar)
