export const Types = {
  SET_MESSAGE: 'messages/SET_MESSAGE',
  CLEAR_MESSAGE: 'messages/CLEAR_MESSAGE',
  NETWORK_ERROR: 'messages/NETWORK_ERROR'
}

const INITIAL_STATE = {
  status: null,
  message: null
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_MESSAGE:
    case Types.NETWORK_ERROR:
      return {
        ...state,
        status: action.payload.status,
        message: action.payload.message
      }

    case Types.CLEAR_MESSAGE:
      return { ...state, status: null, message: null }

    default:
      return state
  }
}

export const Creators = {
  setMessage: (message) => {
    return (dispatch) => {
      dispatch({ type: Types.SET_MESSAGE, payload: message })
    }
  },

  clearMessage: () => {
    return (dispatch) => {
      dispatch({ type: Types.CLEAR_MESSAGE })
    }
  },

  defaultNetworkError: (message = '') => {
    const defaultMessage = {
      status: 503,
      message: `${message} Service unavailable when attempting to fetch resource.`
    }
    return (dispatch) => {
      dispatch({ type: Types.NETWORK_ERROR, payload: defaultMessage })
    }
  }
}
